package es.cinfo.tiivii.core.features.content.view

import com.arkivanov.mvikotlin.core.view.MviView
import es.cinfo.tiivii.core.features.content.model.ViewModel.Content
import es.cinfo.tiivii.core.util.ViewModel.Tree
import es.cinfo.tiivii.core.features.content.view.ContentView.Event
import es.cinfo.tiivii.core.features.content.view.ContentView.Model
import es.cinfo.tiivii.core.util.LoadingModel
import es.cinfo.tiivii.core.util.LoadingModel.ViewModel.LoadState

/**
 * View implementation for the catalogue screen
 */
interface ContentView : MviView<Model, Event> {

    sealed class Event {

        data class LoadContentByWidget(val id: Int, val language: String) : Event()
        data class LoadContentByParticipant(val id: Int, val language: String) : Event()
        data class LoadContentByCategory(val id: Int, val language: String) : Event()
        data class LoadContentById(val id: Int, val language: String) : Event()

        /**
         * Request the load of more content associated with the selected node
         */
        object LoadMoreContent : Event()

        /**
         * Requests a search filter over the selected node
         */
        data class Search(val query: String) : Event()

        /**
         * Requests a search to be cleared and all the content of the related node to be loaded again
         */
        object ClearSearch : Event()

        /**
         * Requests a new sort for the content of the currently selected node
         */
        data class Sort(val sortId: String) : Event()

        /**
         * Request the core to log a catalogue screen view to the analytics backend. It should be send
         * whenever the catalogue screen is viewed. This event is exposed for more grained control
         * for the UI
         */
        object LogView: Event()
    }

    data class Model(
        val title: String?,
        val isSearchAvailable: Boolean,
        val contents: Tree<Content>?,
        val hasMoreContents: Boolean,
        val queryFilter: String?,
        val selectedSort: String?,
        val availableSorts: List<String>?,
        val areContentsLoading: LoadState,
    )

    sealed class Output {
        /**
         * Unexpected error happened
         */
        data class UnexpectedError(val code: String) : Output()

        /**
         * An operation has been request through an [Event] but the operation cannot be done due to some requirements
         * not met. This may happen if an [Event] is requested when is not due. This usually means that the usage of the
         * internal of the component is wrong and should be checked
         * @param reason explains why the operation cannot be executed
         */
        data class IllegalOperationError(val reason: String) : Output()

        /**
         * User session has expired. Re-log is necessary
         */
        object UserSessionExpired : Output()

        /**
         * A request has timed out. This may indicate a problem with the internet connection
         */
        object RequestTimedOut : Output()
    }

}
