package es.cinfo.tiivii.home.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.content.model.ContentTypeModel.Model.ContentType
import es.cinfo.tiivii.core.content.model.WidgetModel
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.layout.LayoutService
import es.cinfo.tiivii.core.layout.model.LayoutModel
import es.cinfo.tiivii.core.layout.model.section.Model
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.rating.RatingModel
import es.cinfo.tiivii.core.usecase.GetOrderedSections
import es.cinfo.tiivii.core.usecase.GetRatingFilter
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import kotlinx.coroutines.*
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.channelFlow
import kotlinx.coroutines.flow.map
import org.kodein.di.instance

internal class LoadDefaultScreenLayoutFlow(private val language: String?): FlowUseCase<LayoutModel.Model.ScreenLoad, LoadDefaultScreenLayoutFlow.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.HOME, UseCaseId.LOAD_DEFAULT_SCREEN_LAYOUT, errorId, networkError) {
        data class WidgetListUnavailable(val error: NetworkError) : Error(
            asErrorId<LoadScreenLayoutFlow.Error.WidgetListUnavailable>(1),
            error)
        data class UserUnavailable(val error: NetworkError) : Error(
            asErrorId<UserUnavailable>(2),
            error)
        object NoDynamicListAvailable : Error(
            asErrorId<NoDynamicListAvailable>(4),
        )
        data class WidgetUnavailable(val error: NetworkError) : Error(
            asErrorId<WidgetUnavailable>(4),
            error
        )
        data class RatingFilterUnavailable(val error: NetworkError) : Error(
            asErrorId<RatingFilterUnavailable>(5),
            error
        )
    }

    override val work: suspend () -> Flow<Outcome<LayoutModel.Model.ScreenLoad, Error>>
        get() = {
            val sectionsOutcome = GetOrderedSections().invokeWith(ComponentId.HOME)
                .mapError {
                    when (it) {
                        is GetOrderedSections.Error.UserUnavailable -> Error.UserUnavailable(it.error)
                        is GetOrderedSections.Error.LayoutConfigUnavailable -> Error.WidgetListUnavailable(it.error)
                    }
                }
            when (sectionsOutcome) {
                is Failure -> {
                    channelFlow {
                        send(sectionsOutcome)
                        close()
                    }
                }
                is Success -> {
                    val sections = sectionsOutcome.value
                    val screenId = sections.find { it.contentPage == Model.Section.ContentPage.DYNAMIC }?.screenId
                    if (screenId != null) {
                        LoadScreenLayoutFlow(screenId, language).invoke()
                            .map {
                                it.mapError { error ->
                                    when (error) {
                                        is LoadScreenLayoutFlow.Error.UserUnavailable -> Error.UserUnavailable(error.error)
                                        is LoadScreenLayoutFlow.Error.WidgetListUnavailable -> Error.WidgetListUnavailable(error.error)
                                        is LoadScreenLayoutFlow.Error.WidgetUnavailable -> Error.WidgetUnavailable(error.error)
                                        is LoadScreenLayoutFlow.Error.RatingFilterUnavailable -> Error.RatingFilterUnavailable(error.error)
                                    }
                                }
                            }
                    } else {
                        channelFlow {
                            send(failure(Error.NoDynamicListAvailable))
                            close()
                        }
                    }
                }
            }
        }
}

internal class LoadScreenLayoutFlow(
    private val id: Int,
    private val inputLanguage: String?
) : FlowUseCase<LayoutModel.Model.ScreenLoad, LoadScreenLayoutFlow.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.HOME, UseCaseId.LOAD_SCREEN_LAYOUT, errorId, networkError) {
        data class WidgetListUnavailable(val error: NetworkError) : Error(
            asErrorId<WidgetListUnavailable>(1),
            error)
        data class UserUnavailable(val error: NetworkError) : Error(
            asErrorId<UserUnavailable>(2),
            error)
        data class WidgetUnavailable(val error: NetworkError) : Error(
            asErrorId<WidgetUnavailable>(3),
            error)
        data class RatingFilterUnavailable(val error: NetworkError) : Error(
            asErrorId<RatingFilterUnavailable>(4),
            error)
    }

    private val userService: UserService by diContainer.instance()
    private val layoutService: LayoutService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val contentService: ContentService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend () -> Flow<Outcome<LayoutModel.Model.ScreenLoad, Error>>
        get() = {
            channelFlow {
                val auth = authService.getStoredAuth()
                val userOutcome = auth?.let {
                    userService.getUser(auth.username, forceRefresh = true)
                        .mapError { Error.UserUnavailable(it) }
                }
                when (userOutcome) {
                    is Failure -> {
                        send(userOutcome)
                    }
                    is Success,
                    null -> {
                        val user = if (userOutcome is Success) {
                            userOutcome.value
                        } else {
                            null
                        }
                        val language = inputLanguage ?: user?.preferredLanguage ?: configModule.getCoreConfig().signup.defaultLanguage
                        val isAuthed = user != null
                        val screenOutcome = layoutService.getScreen(id, language)
                            .mapError { Error.WidgetListUnavailable(it) }
                        when (screenOutcome) {
                            is Failure -> {
                                send(screenOutcome)
                            }
                            is Success -> {
                                val screen = screenOutcome.value
                                var screenLoad = LayoutModel.Model.ScreenLoad(
                                    id = id,
                                    widgets = screen.orderedWidgets.map { widget ->
                                        WidgetModel.Model.WidgetContentLoad(
                                            loadState = LoadingModel.Model.LoadState.LOADING,
                                            type = widget.type ?: WidgetModel.Model.WidgetType.BANNERCLICK,
                                            id = widget.id ?: 0,
                                            widget = null
                                        )
                                    }
                                )
                                send(success(screenLoad))
                                val ratingFilterOutcome = GetRatingFilter().invokeWith(ComponentId.SEARCH).mapError { error ->
                                    when (error) {
                                        is GetRatingFilter.Error.UnavailableRatings -> Error.RatingFilterUnavailable(error.error)
                                    }
                                }
                                when (ratingFilterOutcome) {
                                    is Failure -> {
                                        send(ratingFilterOutcome)
                                    }
                                    is Success -> {
                                        withContext(currentCoroutineContext()) {
                                            screen.orderedWidgets.forEach { widget ->
                                                val userRequired = widget.apiRequest?.contains("{username}") ?: false
                                                if (!userRequired || (userRequired && isAuthed)) {
                                                    val username = if (userRequired) auth?.username else null
                                                    launch {
                                                        val ratingFilter = ratingFilterOutcome.value
                                                        val searchFilters = if (ratingFilter != null) {
                                                            mutableMapOf(RatingModel.Model.RATING_FILTER to ratingFilter)
                                                        } else {
                                                            null
                                                        }
                                                        val widgetContentsOutcome = contentService.getHomeWidgetContents(
                                                            id = widget.id,
                                                            filters = searchFilters,
                                                            username = username,
                                                            language = language
                                                        )
                                                            .mapError { error ->
                                                                Error.WidgetUnavailable(error)
                                                            }
                                                        when (widgetContentsOutcome) {
                                                            is Failure -> {
                                                                screenLoad = updateScreenLoadWithError(widget.id, widget.type, screenLoad)
                                                                send(success(screenLoad))
                                                            }
                                                            is Success -> {
                                                                val widgetLoaded = widgetContentsOutcome.value.widget
                                                                if (widgetLoaded is WidgetModel.Model.Widget.ContentWidget) {
                                                                    widgetLoaded.content.forEach { content ->
                                                                        if (isAuthed) {
                                                                            content.canFav = true
                                                                            if (content.type != ContentType.CONTAINER) {
                                                                                content.canPlay = true
                                                                            }
                                                                            content.isFav = user?.favorites?.contains(content.id.toString()) ?: false
                                                                        }
                                                                    }
                                                                }
                                                                screenLoad = updateScreenLoad(widgetLoaded, screenLoad)
                                                                send(success(screenLoad))
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    screenLoad = updateScreenLoadWithError(widget.id, widget.type, screenLoad)
                                                    send(success(screenLoad))
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }

    private fun updateScreenLoad(
        widget: WidgetModel.Model.Widget,
        previousScreenLoad: LayoutModel.Model.ScreenLoad
    ): LayoutModel.Model.ScreenLoad {
        val widgetLoad = WidgetModel.Model.WidgetContentLoad(
            loadState = LoadingModel.Model.LoadState.LOADED,
            type = widget.type,
            id = widget.id,
            widget = widget,
        )
        val widgetList = previousScreenLoad.widgets.toMutableList()
        val widgetToReplace = widgetList.indexOfFirst { widgetOnList ->
            widgetOnList.id == widget.id
        }
        widgetList[widgetToReplace] = widgetLoad
        return previousScreenLoad.copy(
            widgets = widgetList
        )
    }

    private fun updateScreenLoadWithError(
        widgetId: Int,
        widgetType: WidgetModel.Model.WidgetType,
        previousScreenLoad: LayoutModel.Model.ScreenLoad
    ): LayoutModel.Model.ScreenLoad {
        val widgetLoad = WidgetModel.Model.WidgetContentLoad(
            loadState = LoadingModel.Model.LoadState.RESET,
            type = widgetType,
            id = widgetId,
            widget = null,
        )
        val widgetList = previousScreenLoad.widgets.toMutableList()
        val widgetToReplace = widgetList.indexOfFirst { widgetOnList ->
            widgetOnList.id == widgetId
        }
        widgetList[widgetToReplace] = widgetLoad
        return previousScreenLoad.copy(
            widgets = widgetList
        )
    }

//    private fun unFreeze(widgets: List<WidgetModel.Model.WidgetContentApiLoad>): List<WidgetModel.Model.WidgetContentApiLoad> {
//        return if (PLATFORM_ID == PlatformModel.Model.Platform.IOS) {
//            val finalWidgets = mutableListOf<WidgetModel.Model.WidgetContentApiLoad>()
//            widgets.forEach { widgetLoad ->
//                val modifiableWidget = when (widgetLoad.widget) {
//                    is WidgetModel.Model.Widget.ContentWidget.BannerLarge -> {
//                        val modifiableContents = widgetLoad.widget.content.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(content = modifiableContents)
//                    }
//                    is WidgetModel.Model.Widget.ContentWidget.Banner -> {
//                        val modifiableContents = widgetLoad.widget.content.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(content = modifiableContents)
//                    }
//                    is WidgetModel.Model.Widget.ContentWidget.Circular -> {
//                        val modifiableContents = widgetLoad.widget.content.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(content = modifiableContents)
//                    }
//                    is WidgetModel.Model.Widget.ContentWidget.Featured -> {
//                        val modifiableContents = widgetLoad.widget.content.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(content = modifiableContents)
//                    }
//                    is WidgetModel.Model.Widget.ContentWidget.Live -> {
//                        val modifiableContents = widgetLoad.widget.content.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(content = modifiableContents)
//                    }
//                    is WidgetModel.Model.Widget.ContentWidget.LiveVertical -> {
//                        val modifiableContents = widgetLoad.widget.content.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(content = modifiableContents)
//                    }
//                    is WidgetModel.Model.Widget.ContentWidget.News -> {
//                        val modifiableContents = widgetLoad.widget.content.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(content = modifiableContents)
//                    }
//                    is WidgetModel.Model.Widget.ContentWidget.Poster -> {
//                        val modifiableContents = widgetLoad.widget.content.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(content = modifiableContents)
//                    }
//                    is WidgetModel.Model.Widget.ContentWidget.Progress -> {
//                        val modifiableContents = widgetLoad.widget.content.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(content = modifiableContents)
//                    }
//                    is WidgetModel.Model.Widget.GroupWidget -> {
//                        val modifiableWidgets = widgetLoad.widget.widgets.map {
//                            it.copy()
//                        }
//                        widgetLoad.widget.copy(widgets = modifiableWidgets)
//                    }
//                }
//                finalWidgets.add(
//                    widgetLoad.copy(widget = modifiableWidget)
//                )
//            }
//            finalWidgets
//        } else {
//            widgets
//        }
//    }

}

