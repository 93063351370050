package es.cinfo.tiivii.core.features.detail.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.content.model.CommentModel.Model.Comment
import es.cinfo.tiivii.core.content.model.CommentModel.Model.Comments
import es.cinfo.tiivii.core.content.model.ContentTypeModel.Model.ContentType
import es.cinfo.tiivii.core.content.model.Model
import es.cinfo.tiivii.core.content.model.Model.Content.Companion.ContentFilter
import es.cinfo.tiivii.core.content.model.Model.ContentLoad
import es.cinfo.tiivii.core.content.model.SerialContentModel.Model.SerialContentLoad
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.features.detail.model.DetailModel
import es.cinfo.tiivii.core.layout.LayoutService
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.cas.CasService
import es.cinfo.tiivii.core.modules.cas.model.CasModel
import es.cinfo.tiivii.core.modules.config.ConfigModel.Model.ContentConfig.RefreshUserValuesType
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.platform.PLATFORM_ID
import es.cinfo.tiivii.core.modules.platform.PlatformModel
import es.cinfo.tiivii.core.modules.rating.RatingModel
import es.cinfo.tiivii.core.modules.rating.RatingModel.Model.Companion.RATING_FILTER
import es.cinfo.tiivii.core.modules.share.ShareService
import es.cinfo.tiivii.core.qr.QRService
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort
import es.cinfo.tiivii.core.usecase.CheckUserSession
import es.cinfo.tiivii.core.usecase.GetRatingFilter
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import kotlinx.coroutines.cancel
import kotlinx.coroutines.currentCoroutineContext
import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import org.kodein.di.instance
import kotlin.math.max
import kotlin.math.min
import kotlin.math.roundToInt

internal class LoadContent(private val id: Int, private val payload: String?, private val inputLanguage : String?) : FlowUseCase<ContentLoad, LoadContent.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.DETAIL, UseCaseId.LOAD_CONTENT, errorId, networkError) {
        data class UnavailableUser(val error: NetworkError) : Error(
            asErrorId<UnavailableUser>(1),
            error
        )
        data class UnavailableContent(val error: NetworkError) : Error(
            asErrorId<UnavailableContent>(2),
            error
        )
        data class UnavailableComments(val error: NetworkError) : Error(
            asErrorId<UnavailableComments>(3),
            error
        )
        data class UnavailableRelatedContent(val error: NetworkError) : Error(
            asErrorId<UnavailableRelatedContent>(4),
            error
        )
        data class UnavailableSerialContent(val error: NetworkError) : Error(
            asErrorId<UnavailableSerialContent>(5),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val shareService: ShareService by diContainer.instance()
    private val qrService: QRService by diContainer.instance()
    private val casService: CasService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()
    private val layoutService: LayoutService by diContainer.instance()

    override val work: suspend () -> Flow<Outcome<ContentLoad, Error>>
        get() = {
            flow {
                val emptyContent = ContentLoad(
                    content = null,
                    contentPurchaseInfo = null,
                    nextContent = null,
                    isContentLoading = true,
                    canReport = false,
                    canRate = false,
                    canFav = false,
                    isFavorite = false,
                    playable = Model.Playable.DISABLED,
                    playbackSupport = null,
                    playableChild = null,
                    contentRating = null,
                    serialContent = emptyList(),
                    isSerialContentLoading = true,
                    relatedContent = emptyList(),
                    isRelatedContentLoading = true,
                    comments = null,
                    areCommentsLoading = true,
                    editUrl = null,
                    shareUrl = null,
                    shareQrBase64 = null,
                    reportReasons = emptyList(),
                    productsUrl = null,
                    serialContentsTitle = null
                )
                var content = emptyContent.copy()
                emit(success(content))
                // Load basic content information
                var username = authService.getStoredAuth()?.username
                val user = username?.let { userService.getUser(it).getOrNull() }
                val language = inputLanguage ?: user?.preferredLanguage ?: configModule.getCoreConfig().signup.defaultLanguage
                val userProfile = user?.profile ?: configModule.getCoreConfig().signup.defaultProfile
                if (!payload.isNullOrBlank()) {
                    username = null
                }
                val initialCachedContentStatus = contentService.getCachedContentStatus(id)
                val contentDetail = contentService.getContent(id, payload, language = language, username = username)
                    .mapError { Error.UnavailableContent(it) }
                when (contentDetail) {
                    is Failure -> {
                        emit(failure(contentDetail.error))
                        currentCoroutineContext().cancel()
                    }
                    is Success -> {
                        val productsUrl = if (configModule.getCoreConfig().content.productBuyoutEnabled) {
                            "${configModule.getEnvConfig().frontendUrl}/products/$id"
                        } else {
                            null
                        }
                        val serialContentsTitle = layoutService
                            .getLayoutConfig(userProfile, language).map { it.subContentsLabel }
                            .getOrNull()
                        content = content.copy(
                            content = contentDetail.value,
                            productsUrl = productsUrl,
                            serialContentsTitle = serialContentsTitle
                        )
                        // TODO: Disabling nextContent load (currently not used by the UIs)
                        // Get next content
//                        val ratingFilter = GetRatingFilter().invokeWith(ComponentId.DETAIL).getOrNull()
//                        val searchFilters = if (ratingFilter != null) {
//                            mutableMapOf(RATING_FILTER to ratingFilter)
//                        } else {
//                            null
//                        }
//                        val nextContent = contentService.getNextContent(id, filters = searchFilters).getOrNull()
//                        if (nextContent?.id != id) {
//                            content = content.copy(
//                                nextContent = nextContent,
//                            )
//                        }
                        // Build edit url for the content
                        if (username == contentDetail.value.author) {
                            val adminUrl = configModule.getEnvConfig().adminUrl
                            val editUrl = if (adminUrl.isNullOrBlank()) {
                                null
                            } else {
                                when (contentDetail.value.type) {
                                    ContentType.LIVE ->
                                        "$adminUrl/petisGo/petisco-live-detail.html?id=$id"
                                    ContentType.LIVE_TIIVII ->
                                        "$adminUrl/petisGo/petisco-tiivii-detail.html?id=$id"
                                    ContentType.VOD,
                                    ContentType.VOD_360,
                                    ContentType.VOD_FORKED,
                                    ContentType.CONTAINER,
                                    ContentType.AR,
                                    ContentType.VR,
                                    ContentType.IFRAME,
                                    ContentType.NEWS ->
                                        "$adminUrl/petisGo/petisco-detail.html?id=$id"
                                    ContentType.PDF,
                                    ContentType.EX_LIVE,
                                    ContentType.EX_VOD,
                                    ContentType.EX_YOUTUBE,
                                    ContentType.UNKNOWN ->
                                        null
                                    else ->
                                        null
                                }
                            }
                            content = content.copy(
                                editUrl = editUrl
                            )
                        }
                        // Load report reasons
                        if (configModule.getCoreConfig().content.enableReports) {
                            val reportReasons = contentService.getContentReports(language).getOrNull()
                            content = content.copy(
                                reportReasons = reportReasons ?: emptyList(),
                            )
                        }
                        // Get share url and QR
                        val shareUrl = shareService.getDetailLink(
                            id,
                            contentDetail.value.title,
                            contentDetail.value.description,
                            contentDetail.value.banner?.url).getOrNull()
                        content = content.copy(
                            shareUrl = shareUrl,
                        )
                        shareUrl?.let {
                            val shareQrBase64 = qrService.qrAsBase64(shareUrl, 300, 300)
                            content = content.copy(
                                shareQrBase64 = shareQrBase64
                            )
                        }
                        // Check playback support
                        val playbackSupport: Model.PlaybackSupport?
                        val playable: Model.Playable
                        val userSessionOutcome = CheckUserSession().invokeWith(ComponentId.DETAIL)
                        val casOutcome = getCasOutcome(contentDetail.value.id)
                        playbackSupport = getContentPlaybackSupport(
                            casOutcome = casOutcome,
                            contentType = contentDetail.value.type,
                            contentRating = contentDetail.value.rating,
                            vodCheck = DetailModel.Model.VodCheck(
                                smil = contentDetail.value.smil,
                                adminTiiviiUrl = contentDetail.value.adminTiiviiUrl,
                                nimbleUrls = contentDetail.value.nimbleUrls
                            ),
                            iframeUrl = contentDetail.value.iframeUrl,
                            iframeUrlCheck = true,
                            userSessionOutcome = userSessionOutcome
                        )
                        val contentPurchaseInfo = if (playbackSupport is Model.PlaybackSupport.Ready) {
                            DetailModel.Model.ContentPurchaseInfo(playbackSupport.isFree, playbackSupport.products)
                        } else {
                            null
                        }
                        val isPlayable = isPlayable(playbackSupport, contentDetail.value.type)
                        playable = if (isPlayable) {
                            Model.Playable.DIRECT
                        } else {
                            Model.Playable.DISABLED
                        }
                        content = content.copy(
                            canReport = isPlayable,
                            canFav = isPlayable,
                            canRate = isPlayable,
                            playable = playable,
                            playbackSupport = playbackSupport,
                            contentPurchaseInfo = contentPurchaseInfo
                        )
                        // Check for user related information
                        val auth = authService.getStoredAuth()
                        if (auth != null) {
                            // Refreshing user incurs on a load penalty but ensures favorites and ratings are updated
                            val userRefresh = when (configModule.getCoreConfig().content.refreshUserValuesOnContentLoad) {
                                RefreshUserValuesType.ALWAYS -> true
                                RefreshUserValuesType.NEVER -> false
                                RefreshUserValuesType.ON_CONTENT_REFRESHED ->
                                    initialCachedContentStatus == Model.CachedContentStatus.EXPIRED ||
                                    initialCachedContentStatus == Model.CachedContentStatus.MISSING
                                RefreshUserValuesType.ON_CONTENT_FIRST_LOAD ->
                                    initialCachedContentStatus == Model.CachedContentStatus.MISSING
                            }
                            val userOutcome = userService.getUser(auth.username, forceRefresh = userRefresh)
                                .mapError { Error.UnavailableUser(it) }
                            when (userOutcome) {
                                is Failure -> {
                                    emit(failure(userOutcome.error))
                                }
                                is Success -> {
                                    content = content.copy(
                                        isFavorite = userOutcome.value.favorites?.contains(id.toString()) ?: false,
                                        contentRating = userOutcome.value.ratings?.find { it.contentId.compareTo(id) == 0 }?.rating,
                                    )
                                }
                            }
                        }
                        content = content.copy(
                            isContentLoading = false
                        )
                        if (playbackSupport is Model.PlaybackSupport.Locked.Rating) {
                            content = emptyContent.copy(
                                isContentLoading = false,
                                isSerialContentLoading = false,
                                isRelatedContentLoading = false,
                                areCommentsLoading = false
                            )
                            emit(success(content))
                            currentCoroutineContext().cancel()
                        } else {
                            emit(success(content))
                        }
                        // Get serial content
                        val ratingFilterOutcome = GetRatingFilter().invokeWith(ComponentId.DETAIL).mapError {
                            when (it) {
                                is GetRatingFilter.Error.UnavailableRatings -> Error.UnavailableSerialContent(it.error)
                            }
                        }
                        when (ratingFilterOutcome) {
                            is Failure -> {
                                emit(failure(ratingFilterOutcome.error))
                            }
                            is Success -> {
                                val ratingFilters = if (ratingFilterOutcome.value != null) {
                                    mutableMapOf(RATING_FILTER to ratingFilterOutcome.value)
                                } else {
                                    null
                                }
                                val serialContentOutcome = getSerialContent(contentDetail.value, ratingFilters)
                                if (serialContentOutcome != null) {
                                    when (serialContentOutcome) {
                                        is Failure -> {
                                            emit(failure(serialContentOutcome.error))
                                        }
                                        is Success -> {
                                            if (contentDetail.value.type == ContentType.CONTAINER) {
                                                var firstChild = serialContentOutcome.value.contents.firstOrNull()
                                                while (firstChild?.type == ContentType.CONTAINER) {
                                                    val children = contentService.getSerialContents(
                                                        id = firstChild.id,
                                                        filters = ratingFilters,
                                                        page = 1,
                                                        sort = Sort.byEpisode.param
                                                    )
                                                        .getOrNull()
                                                    firstChild = children?.contents?.firstOrNull()
                                                }
                                                if (firstChild != null) {
                                                    val childCasOutcome = getCasOutcome(firstChild.id)
                                                    val serialContentPlaybackSupport = getContentPlaybackSupport(
                                                        casOutcome = childCasOutcome,
                                                        contentType = firstChild.type,
                                                        contentRating = firstChild.rating,
                                                        vodCheck = null,
                                                        iframeUrl = null,
                                                        iframeUrlCheck = false,
                                                        userSessionOutcome = userSessionOutcome
                                                    )
                                                    val isChildPlayable = isPlayable(serialContentPlaybackSupport, firstChild.type)
                                                    if (isChildPlayable) {
                                                        content = content.copy(
                                                            playable = Model.Playable.CHILDREN,
                                                            playbackSupport = serialContentPlaybackSupport,
                                                            playableChild = Model.PlayableChild(
                                                                id = firstChild.id,
                                                                title = firstChild.title,
                                                                subtitle = firstChild.subtitle,
                                                                episode = firstChild.episode
                                                            )
                                                        )
                                                    }
                                                }
                                            }
                                            content = content.copy(
                                                serialContent = serialContentOutcome.value.contents
                                            )
                                        }
                                    }
                                }
                            }
                        }
                        content = content.copy(
                            isSerialContentLoading = false
                        )
                        emit(success(content))
                        // Get related content
                        when (ratingFilterOutcome) {
                            is Failure -> {
                                emit(failure(Error.UnavailableRelatedContent(ratingFilterOutcome.error.error)))
                            }
                            is Success -> {
                                var contentFilters = if (ratingFilterOutcome.value != null) {
                                    mutableMapOf(RATING_FILTER to ratingFilterOutcome.value)
                                } else {
                                    null
                                }
                                if (configModule.getCoreConfig().content.containerFilterEnabled) {
                                    if (contentFilters == null) {
                                        contentFilters = mutableMapOf(
                                            ContentFilter.key to ContentFilter.value)
                                    } else {
                                        contentFilters[ContentFilter.key] = ContentFilter.value
                                    }
                                }
                                val relatedContent =
                                    contentService.getRelatedContents(
                                        contentId = id,
                                        categoryId = contentDetail.value.category.id,
                                        tags = contentDetail.value.tags,
                                        filters = contentFilters,
                                        shuffleContent = configModule.getCoreConfig().content.shuffleRelatedContent
                                    )
                                        .mapError { Error.UnavailableRelatedContent(it) }
                                when (relatedContent) {
                                    is Failure -> {
                                        emit(failure(relatedContent.error))
                                    }
                                    is Success -> {
                                        content = content.copy(
                                            relatedContent = relatedContent.value,
                                        )
                                    }
                                }
                            }
                        }
                        content = content.copy(
                            isRelatedContentLoading = false,
                        )
                        emit(success(content))
                        // Get comments
                        if (configModule.getCoreConfig().content.enableComments) {
                            val comments = contentService.getComments(contentId = id, page = 1)
                                .mapError { Error.UnavailableComments(it) }
                            when (comments) {
                                is Failure -> {
                                    emit(failure(comments.error))
                                }
                                is Success -> {
                                    content = content.copy(
                                        comments = comments.value
                                    )
                                }
                            }
                        }
                        content = content.copy(
                            areCommentsLoading = false
                        )
                        emit(success(content))
                    }
                }
            }
        }

    private fun isContentRatingLockedForGuests(contentRating: RatingModel.Model.AgeRating): Boolean {
        val contentRatingMinAge = contentRating.minAge
        val guestUserRatingMaxAge = configModule.getCoreConfig().content.maxGuestAgeRating
        return if (guestUserRatingMaxAge == null) {
            false
        } else {
            contentRating.isAdultContent || (contentRatingMinAge > guestUserRatingMaxAge)
        }
    }

    private fun isPlayable(
        playbackSupport: Model.PlaybackSupport, contentType: ContentType
    ): Boolean {

        if (contentType == ContentType.PDF || contentType == ContentType.EX_LIVE || contentType == ContentType.EX_VOD || contentType == ContentType.EX_YOUTUBE) {
            return true
        }

        val nonPlayableCases = listOf(
            Model.PlaybackSupport.Locked.MissingAchievements::class,
            Model.PlaybackSupport.Locked.MissingProduct::class,
            Model.PlaybackSupport.Locked.Rating::class,
            Model.PlaybackSupport.Locked.Other::class,
            Model.PlaybackSupport.Unavailable.ProductBuyoutDisabled::class,
            Model.PlaybackSupport.Unavailable.MissingIframe::class,
            Model.PlaybackSupport.Unavailable.MissingVideoUrl::class,
            Model.PlaybackSupport.Unavailable.ServiceUnavailable::class,
            Model.PlaybackSupport.Unsupported.NoPlatformSupport::class,
            Model.PlaybackSupport.Unsupported.NoContentTypeSupport::class,
            Model.PlaybackSupport.Locked.UserSessionRequired::class
        )

        if (nonPlayableCases.any { it.isInstance(playbackSupport) }) {
            return false
        }

        return playbackSupport is Model.PlaybackSupport.Ready
    }

    private suspend fun getCasOutcome(contentId: Int) =
        casService.getContentHash(contentId)

//    private fun isContentLockedByRating(casOutcome: Outcome<CasModel.Model.ContentHash, CasService.Error>): Boolean {
//        return when (casOutcome) {
//            is Failure -> {
//                casOutcome.error is CasService.Error.RatingLocked
//            }
//            is Success -> false
//        }
//    }

    private suspend fun getContentPlaybackSupport(
        casOutcome: Outcome<CasModel.Model.ContentHash, CasService.Error>,
        contentType: ContentType,
        contentRating: RatingModel.Model.AgeRating,
        vodCheck: DetailModel.Model.VodCheck?,
        iframeUrl: String?,
        iframeUrlCheck: Boolean,
        userSessionOutcome: Outcome<Unit, CheckUserSession.Error>
    ): Model.PlaybackSupport {
        val username = authService.getStoredAuth()?.username
        if (username == null) {
            val contentRatingLockedForGuests = isContentRatingLockedForGuests(contentRating)
            if (contentRatingLockedForGuests) {
                return Model.PlaybackSupport.Locked.Rating
            }
        }
        return when (userSessionOutcome) {
            is Failure -> {
                Model.PlaybackSupport.Locked.UserSessionRequired
            }
            is Success -> {
                when (contentType) {
                    ContentType.VOD,
                    ContentType.LIVE,
                    ContentType.LIVE_TIIVII,
                    ContentType.VOD_360,
                    ContentType.VOD_FORKED -> {
                        when (casOutcome) {
                            is Failure -> {
                                when (casOutcome.error) {
                                    is CasService.Error.CasNotAvailable ->
                                        Model.PlaybackSupport.Unavailable.ServiceUnavailable
                                    is CasService.Error.MissingAchievements ->
                                        Model.PlaybackSupport.Locked.MissingAchievements(casOutcome.error.achievements)
                                    is CasService.Error.ProductNotPurchased -> {
                                        if (configModule.getCoreConfig().content.productBuyoutEnabled) {
                                            Model.PlaybackSupport.Locked.MissingProduct(casOutcome.error.products)
                                        } else {
                                            Model.PlaybackSupport.Unavailable.ProductBuyoutDisabled
                                        }
                                    }
                                    CasService.Error.RatingLocked ->
                                        Model.PlaybackSupport.Locked.Rating
                                    CasService.Error.UserNotAllowed,
                                    CasService.Error.Unknown ->
                                        Model.PlaybackSupport.Locked.Other
                                }
                            }
                            is Success -> {
                                if (contentType != ContentType.LIVE && contentType != ContentType.LIVE_TIIVII && vodCheck != null) {
                                    if (vodCheck.isVideoUrlAvailable()) {
                                        Model.PlaybackSupport.Ready(casOutcome.value.isFree, casOutcome.value.products)
                                    } else {
                                        Model.PlaybackSupport.Unavailable.MissingVideoUrl
                                    }
                                } else {
                                    Model.PlaybackSupport.Ready(casOutcome.value.isFree, casOutcome.value.products)
                                }
                            }
                        }
                    }
                    ContentType.VR -> {
                        if (PLATFORM_ID == PlatformModel.Model.Platform.WEB) {
                            Model.PlaybackSupport.Ready()
                        } else {
                            Model.PlaybackSupport.Unsupported.NoPlatformSupport
                        }
                    }
                    ContentType.AR -> {
                        when (PLATFORM_ID) {
                            PlatformModel.Model.Platform.ANDROID_TV,
                            PlatformModel.Model.Platform.WEB ->
                                Model.PlaybackSupport.Unsupported.NoPlatformSupport
                            PlatformModel.Model.Platform.ANDROID -> {
                                // Check unity support
                                Model.PlaybackSupport.Ready()
                            }
                            PlatformModel.Model.Platform.IOS ->
                                // Check unity support
                                Model.PlaybackSupport.Ready()
                        }
                    }
                    ContentType.IFRAME -> {
                        when (PLATFORM_ID) {
                            PlatformModel.Model.Platform.WEB ->
                                if (iframeUrlCheck && iframeUrl == null) {
                                    Model.PlaybackSupport.Unavailable.MissingIframe
                                } else {
                                    Model.PlaybackSupport.Ready()
                                }
                            // Should this be supported on Android & iOS?
                            PlatformModel.Model.Platform.ANDROID_TV,
                            PlatformModel.Model.Platform.ANDROID,
                            PlatformModel.Model.Platform.IOS ->
                                Model.PlaybackSupport.Unsupported.NoPlatformSupport
                        }
                    }
                    ContentType.NEWS,
                    ContentType.CONTAINER,
                    ContentType.UNKNOWN -> {
                        Model.PlaybackSupport.Unsupported.NoContentTypeSupport
                    }
                    ContentType.PDF,
                    ContentType.EX_LIVE,
                    ContentType.EX_VOD,
                    ContentType.EX_YOUTUBE -> {
                        Model.PlaybackSupport.Ready()
                    }
                }
            }
        }
    }

    private suspend fun getSerialContent(content: Model.Content, searchFilters: MutableMap<String, String>?): Outcome<SerialContentLoad, Error>? {
        var serialContent: Outcome<SerialContentLoad, Error>? = null
        if (content.isSerial) {
            serialContent =
                if (content.parentId == null ||
                    content.type == ContentType.CONTAINER
                ) {
                    // Get my children (Parent with/without grandparent)
                    contentService.getSerialContents(
                        id = content.id,
                        filters = searchFilters,
                        page = 1,
                        sort = Sort.byEpisode.param
                    )
                        .mapError { Error.UnavailableSerialContent(it) }
                } else {
                    // Get my siblings (Child with siblings)
                    contentService.getSerialContents(
                        id = content.parentId,
                        filters = searchFilters,
                        page = 1,
                        sort = Sort.byEpisode.param
                    )
                        .mapError { Error.UnavailableSerialContent(it) }
                }
        }
        return serialContent
    }
}

internal class LoadCommentPage(private val contentId: Int, private val page: Int) :
    OutcomeUseCase<Comments, LoadCommentPage.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.DETAIL, UseCaseId.LOAD_CONTENT_COMMENTS_PAGE, errorId, networkError) {
        data class UnavailableComments(val error: NetworkError) : Error(
            asErrorId<UnavailableComments>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Comments, Error>
        get() = {
            contentService.getComments(contentId = contentId, page = page)
                .mapError { Error.UnavailableComments(it) }
        }
}

internal class RateContent(private val content: Model.Content, private val rating: Float) :
    OutcomeUseCase<Model.RatedContent, RateContent.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.DETAIL, UseCaseId.RATE_CONTENT, errorId, networkError) {
        data class RatingError(val error: NetworkError) : Error(
            asErrorId<RatingError>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Model.RatedContent, Error>
        get() = {
            val sanitizedRating = when (content.type) {
                ContentType.VOD,
                ContentType.LIVE,
                ContentType.LIVE_TIIVII,
                ContentType.VOD_360,
                ContentType.VOD_FORKED,
                ContentType.CONTAINER,
                ContentType.AR,
                ContentType.VR,
                ContentType.IFRAME,
                ContentType.PDF,
                ContentType.EX_LIVE,
                ContentType.EX_VOD,
                ContentType.EX_YOUTUBE -> max(min(rating.roundToInt(), 10), 0)
                ContentType.UNKNOWN -> max(min(rating.roundToInt(), 5), 0)
                ContentType.NEWS -> max(min(rating.roundToInt(), 1), 0)
            }
            val ratings = contentService.rateContent(content.id, sanitizedRating)
                .mapError { Error.RatingError(it) }.getOrAbort()
            userService.updateCachedUserRatings(ratings)
            // Log score
            LogEvent(AnalyticsModel.Action.SendRating, content.id, AnalyticsModel.Action.SendRating.SCORE_KEY to sanitizedRating.toString()).invoke()
            val user = authService.getStoredAuth()?.username
            val language = user?.let { userService.getUser(it).getOrNull()?.preferredLanguage } ?: configModule.getCoreConfig().signup.defaultLanguage
            val updatedContent = contentService.getContent(content.id, hitCache = false, language = language, username = user)
                .mapError { Error.RatingError(it) }.getOrAbort()
            success(Model.RatedContent(updatedContent, sanitizedRating))
        }
}

internal class DeleteRating(private val contentId: Int) : OutcomeUseCase<Model.Content, DeleteRating.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.DETAIL, UseCaseId.DELETE_CONTENT_RATING, errorId, networkError) {
        data class RatingError(val error: NetworkError) : Error(
            asErrorId<RatingError>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()
    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Model.Content, Error>
        get() = {
            val ratings = contentService.deleteRating(contentId)
                .mapError { Error.RatingError(it) }.getOrAbort()
            userService.updateCachedUserRatings(ratings)
            // Log score delete
            LogEvent(AnalyticsModel.Action.RemoveRating, contentId).invoke()
            val user = authService.getStoredAuth()?.username
            val language = user?.let { userService.getUser(it).getOrNull()?.preferredLanguage } ?: configModule.getCoreConfig().signup.defaultLanguage
            val updatedContent = contentService.getContent(contentId, hitCache = false, language = language, username = user)
                .mapError { Error.RatingError(it) }.getOrAbort()
            success(updatedContent)
        }
}

internal class AddComment(private val content: Model.Content, private val comment: String) :
    OutcomeUseCase<Comment, AddComment.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.DETAIL, UseCaseId.ADD_COMMENT, errorId, networkError) {
        data class CommentError(val error: NetworkError) : Error(
            asErrorId<CommentError>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Comment, Error>
        get() = {
            val storedComment = contentService.addComment(content.id, comment)
                .mapError {
                    Error.CommentError(it)
                }.getOrAbort()
            LogEvent(
                AnalyticsModel.Action.SendComment,
                content.id,
                AnalyticsModel.Action.SendComment.TEXT_KEY to storedComment.text).invoke()
            success(storedComment)
        }
}

internal class RemoveComment(private val contentId: Int, private val commentId: Int) :
    OutcomeUseCase<Unit, RemoveComment.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.DETAIL, UseCaseId.REMOVE_COMMENT, errorId, networkError) {
        data class CommentError(val error: NetworkError) : Error(
            asErrorId<CommentError>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            contentService.removeComment(commentId)
                .on {
                    // Log comment delete
                    LogEvent(AnalyticsModel.Action.RemoveComment, contentId, AnalyticsModel.Action.RemoveComment.COMMENT_KEY to commentId.toString()).invoke()
                }
                .mapError { Error.CommentError(it) }
        }
}

internal class UpdateComment(private val contentId: Int, private val commentId: Int, private val comment: String) :
    OutcomeUseCase<Comment, UpdateComment.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.DETAIL, UseCaseId.UPDATE_COMMENT, errorId, networkError) {
        data class CommentError(val error: NetworkError) : Error(
            asErrorId<CommentError>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Comment, Error>
        get() = {
            contentService.updateComment(commentId, comment)
                .on {
                    // Log comment update
                    LogEvent(
                        AnalyticsModel.Action.UpdateComment,
                        contentId,
                        AnalyticsModel.Action.UpdateComment.TEXT_KEY to comment).invoke()
                }
                .mapError { Error.CommentError(it) }
        }
}

internal class RefreshComments(private val contentId: Int) :
    OutcomeUseCase<Comments, RefreshComments.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.DETAIL, UseCaseId.REFRESH_COMMENTS, errorId, networkError) {
        data class UnavailableComments(val error: NetworkError) : Error(
            asErrorId<UnavailableComments>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Comments, Error>
        get() = {
            contentService.getComments(contentId, page = 1)
                .mapError { Error.UnavailableComments(it) }
        }
}

internal class ReportContent(
    private val contentId: Int,
    private val reportId: Int,
    private val userMessage: String? = null
) : OutcomeUseCase<Unit, ReportContent.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.DETAIL, UseCaseId.REPORT_CONTENT, errorId, networkError) {
        data class ReportUnavailable(val error: NetworkError): Error(
            asErrorId<ReportUnavailable>(1),
            error
        )
    }

    private val contentService: ContentService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            contentService.reportContent(contentId, reportId, userMessage)
                .on {
                    // Log report
                    LogEvent(
                        AnalyticsModel.Action.ReportContent,
                        contentId,
                        AnalyticsModel.Action.ReportContent.REASON_KEY to reportId.toString()).invoke()
                }
                .mapError { Error.ReportUnavailable(it) }
        }
}
