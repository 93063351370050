package es.cinfo.tiivii.core.features.playback.usecase

import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.ErrorId
import es.cinfo.tiivii.core.UseCaseId
import es.cinfo.tiivii.core.content.ContentService
import es.cinfo.tiivii.core.content.model.ContentTypeModel
import es.cinfo.tiivii.core.error.CodedError
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.error.asErrorId
import es.cinfo.tiivii.core.features.playback.native.model.PlaybackModel.Model.PlaybackLockReason
import es.cinfo.tiivii.core.modules.auth.AuthService
import es.cinfo.tiivii.core.modules.cas.CasService
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.core.modules.geolocation.LocationService
import es.cinfo.tiivii.core.features.playback.model.Model
import es.cinfo.tiivii.core.modules.share.ShareService
import es.cinfo.tiivii.core.usecase.CheckUserSession
import es.cinfo.tiivii.core.user.UserService
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal class GetVideoUrl(private val contentId: Int, private val ignoreList: String?) : OutcomeUseCase<Model.ContentLoad, GetVideoUrl.Error>() {
    sealed class Error(errorId: ErrorId, networkError: NetworkError? = null)
        : CodedError(ComponentId.PLAYBACK, UseCaseId.GET_VIDEO_URL, errorId, networkError) {
        data class ContentUnavailable(val error: NetworkError): Error(
            asErrorId<ContentUnavailable>(2),
            error
        )
        data class PlaybackLocked(val reason: PlaybackLockReason): Error(
            asErrorId<PlaybackLocked>(3)
        )
        data class CasUnavailable(val error: NetworkError): Error(
            asErrorId<CasUnavailable>(4),
            error
        )
        data class ContentNotSupported(val reason: String): Error(
            asErrorId<ContentNotSupported>(5)
        )
    }

    private val authService: AuthService by diContainer.instance()
    private val userService: UserService by diContainer.instance()
    private val contentService: ContentService by diContainer.instance()
    private val locationService: LocationService by diContainer.instance()
    private val casService: CasService by diContainer.instance()
    private val shareService: ShareService by diContainer.instance()
    private val configModule: ConfigModule by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Model.ContentLoad, Error>
        get() = {
            val user = authService.getStoredAuth()?.username
            val language = user?.let { userService.getUser(it).getOrNull()?.preferredLanguage } ?:
                configModule.getCoreConfig().signup.defaultLanguage
            val content = contentService.getContent(contentId, language = language, username = user)
                .mapError {
                    Error.ContentUnavailable(it)
                }.getOrAbort()
            when (val userSessionOutcome = CheckUserSession().invokeWith(ComponentId.PLAYBACK)) {
                is Failure -> {
                    when (userSessionOutcome.error) {
                        is CheckUserSession.Error.CasUnavailable ->
                            failure(Error.CasUnavailable(userSessionOutcome.error.error))
                        is CheckUserSession.Error.UserSessionRequired ->
                            failure(Error.PlaybackLocked(PlaybackLockReason.UserSessionRequired))
                    }
                }
                is Success -> {
                    when (content.type) {
                        ContentTypeModel.Model.ContentType.IFRAME -> {
                            if (content.iframeUrl == null) {
                                failure(Error.ContentNotSupported("No streaming url available"))
                            } else {
                                success(Model.ContentLoad.Video(content.iframeUrl, content.type))
                            }
                        }
                        ContentTypeModel.Model.ContentType.VR,
                        ContentTypeModel.Model.ContentType.VOD,
                        ContentTypeModel.Model.ContentType.LIVE,
                        ContentTypeModel.Model.ContentType.LIVE_TIIVII,
                        ContentTypeModel.Model.ContentType.VOD_360,
                        ContentTypeModel.Model.ContentType.PDF,
                        ContentTypeModel.Model.ContentType.EX_VOD,
                        ContentTypeModel.Model.ContentType.EX_LIVE,
                        ContentTypeModel.Model.ContentType.EX_YOUTUBE,
                        ContentTypeModel.Model.ContentType.VOD_FORKED -> {
                            var videoUrl = "${configModule.getEnvConfig().backendUrl}/player/${configModule.getEnvConfig().apiName}/$contentId?"
                            val token = authService.getStoredAuth()?.params?.accessToken
                            token?.let {
                                videoUrl += "token=$token&"
                            }
                            val contentHash = casService.getContentHash(contentId)
                                .mapError {
                                    when (it) {
                                        is CasService.Error.CasNotAvailable ->
                                            Error.CasUnavailable(it.error)
                                        CasService.Error.UserNotAllowed ->
                                            Error.PlaybackLocked(PlaybackLockReason.Cas)
                                        is CasService.Error.MissingAchievements ->
                                            Error.PlaybackLocked(PlaybackLockReason.MissingAchievements(it.achievements))
                                        is CasService.Error.ProductNotPurchased ->
                                            Error.PlaybackLocked(PlaybackLockReason.ProductNotPurchased(it.products))
                                        CasService.Error.RatingLocked ->
                                            Error.PlaybackLocked(PlaybackLockReason.RatingLocked)
                                        CasService.Error.Unknown ->
                                            Error.PlaybackLocked(PlaybackLockReason.Cas)
                                    }
                                }.getOrAbort()
                            contentHash.hash?.let {
                                videoUrl += "hash=$it"
                            }
                            if (content.geolocationActive) {
                                val username = authService.getStoredAuth()?.username
                                if (username != null) {
                                    locationService.start(username, LocationService.LOCATION_RETRIEVAL_INTERVAL_MS)
                                }
                            }
                            ignoreList?.let {
                                videoUrl += "&ignore=$ignoreList"
                            }
                            success(Model.ContentLoad.Video(videoUrl, content.type))
                        }
                        ContentTypeModel.Model.ContentType.AR -> {
                            shareService.getPlayerLink(
                                content.id,
                                content.type.toString(),
                                content.title,
                                content.description,
                                content.banner?.url)
                                .mapError {
                                    Error.ContentUnavailable(it)
                                }
                                .map {
                                    Model.ContentLoad.Ar(it)
                                }
                        }
                        ContentTypeModel.Model.ContentType.CONTAINER,
                        ContentTypeModel.Model.ContentType.NEWS,
                        ContentTypeModel.Model.ContentType.UNKNOWN -> {
                            failure(Error.ContentNotSupported("Unsupported content type ${content.type}"))
                        }
                    }
                }
            }
        }
}

internal class StopLocationTracking : OutcomeUseCase<Unit, StopLocationTracking.Error>() {
    sealed class Error {
    }

    private val locationService: LocationService by diContainer.instance()

    override val work: suspend TryOutcomeContext<Error>.() -> Outcome<Unit, Error>
        get() = {
            locationService.stop()
            success()
        }
}