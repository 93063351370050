package es.cinfo.tiivii.core.features.content.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.content.model.Model
import es.cinfo.tiivii.core.features.content.model.Model.Content
import es.cinfo.tiivii.core.features.content.model.ViewModel
import es.cinfo.tiivii.core.features.content.store.ContentStore.*
import es.cinfo.tiivii.core.sorting.SortModel
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.core.util.Model.Tree

internal interface ContentStore :
    Store<Intent, State, Label> {

    sealed class Action {
        object LoadOrders: Action()
    }

    sealed class Intent {
        data class LoadContentByWidget(val id: Int, val language: String) : Intent()
        data class LoadContentByParticipant(val id: Int, val language: String) : Intent()
        data class LoadContentByCategory(val id: Int, val language: String) : Intent()
        data class LoadContentById(val id: Int, val language: String) : Intent()
        object LoadMoreContent : Intent()
        data class Search(val query: String) : Intent()
        object ClearSearch : Intent()
        data class Sort(val sortId: String) : Intent()
        object LogView: Intent()
    }

    enum class LoadType {
        WIDGET, PARTICIPANT, CATEGORY, SERIAL
    }

    data class State(
        val title: String?,
        val loadedBy: LoadType?,
        val id: Int?,
        val isSearchAvailable: Boolean,
        val contents: Tree<ViewModel.Content, Content>?,
        val hasMoreContents: Boolean,
        val queryFilter: String?,
        val selectedSort: SortModel.Model.Sort?,
        val availableSorts: List<SortModel.Model.Sort>?,
        val currentPage: Int,
        val loadingContents: LoadState,
    )

    sealed class Label {
        data class UnexpectedError(val code: String) : Label()
        data class IllegalOperationError(val reason: String) : Label()
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()
    }

}