package es.cinfo.tiivii.core.features.detail.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.content.ContentService.Companion.DEFAULT_COMMENT_LIMIT
import es.cinfo.tiivii.core.content.ContentService.Companion.DEFAULT_COMMENT_SORT
import es.cinfo.tiivii.core.content.model.CommentModel.Model.Comment
import es.cinfo.tiivii.core.content.model.CommentModel.Model.Comments
import es.cinfo.tiivii.core.content.model.Model
import es.cinfo.tiivii.core.content.model.Model.ContentLoad
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action.PageView
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action.SelectItem
import es.cinfo.tiivii.core.usecase.AddContentToFavorites
import es.cinfo.tiivii.core.usecase.RemoveContentFromFavorites
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.core.features.detail.store.DetailStore.*
import es.cinfo.tiivii.core.features.detail.usecase.AddComment
import es.cinfo.tiivii.core.features.detail.usecase.DeleteRating
import es.cinfo.tiivii.core.features.detail.usecase.LoadCommentPage
import es.cinfo.tiivii.core.features.detail.usecase.LoadContent
import es.cinfo.tiivii.core.features.detail.usecase.RateContent
import es.cinfo.tiivii.core.features.detail.usecase.RefreshComments
import es.cinfo.tiivii.core.features.detail.usecase.RemoveComment
import es.cinfo.tiivii.core.features.detail.usecase.ReportContent
import es.cinfo.tiivii.core.features.detail.usecase.UpdateComment
import es.cinfo.tiivii.core.modules.game.model.GameActions
import es.cinfo.tiivii.core.usecase.SendGameAction
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.core.util.asLoadState
import es.cinfo.tiivii.di.diContainer
import kotlinx.coroutines.flow.collect
import org.kodein.di.instance

internal class DetailStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        object ContentRequested : Result()
        data class ContentLoaded(val contentLoad: ContentLoad) : Result()
        object ErrorLoadingContent : Result()
        object ErrorLoadingComments : Result()
        object ErrorLoadingSerialContent : Result()
        object ErrorLoadingRelatedContent : Result()
        data class LoadedComments(val comments: Comments) : Result()
        object AddedAsFavorite : Result()
        object RemovedFromFavorites : Result()
        data class ContentRated(val rating: Int, val content: Model.Content) : Result()
        data class DeletedRating(val content: Model.Content) : Result()
        data class CommentAdded(val comment: Comment) : Result()
        data class CommentRemoved(val commentId: Int) : Result()
        data class CommentUpdated(val comment: Comment) : Result()
        data class CommentsRefreshed(val comments: Comments) : Result()
    }

    fun create(): DetailStore =
        object :
            DetailStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "DetailStore",
                initialState = State(
                    content = null,
                    purchaseInfo = null,
                    nextContent = null,
                    canReport = null,
                    playable = null,
                    playbackSupport = null,
                    playableChild = null,
                    canFav = null,
                    isFavorite = null,
                    canRate = null,
                    userRating = null,
                    availableReportReasons = emptyList(),
                    editUrl = null,
                    shareUrl = null,
                    shareQrBase64 = null,
                    isContentLoading = LoadState.RESET,
                    productsUrl = null,
                    serialContentsTitle = null,

                    serialContent = emptyList(),
                    isSerialContentLoading = LoadState.RESET,

                    relatedContent = emptyList(),
                    isRelatedContentLoading = LoadState.RESET,

                    comments = null,
                    areMoreCommentsAvailable = false,
                    areCommentsLoading = LoadState.RESET
                ),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                Result.ContentRequested -> State(
                    content = null,
                    purchaseInfo = null,
                    nextContent = null,
                    canReport = null,
                    playable = null,
                    playbackSupport = null,
                    playableChild = null,
                    canFav = null,
                    isFavorite = null,
                    canRate = null,
                    userRating = null,
                    isContentLoading = LoadState.RESET,
                    availableReportReasons = emptyList(),
                    editUrl = null,
                    shareUrl = null,
                    shareQrBase64 = null,
                    productsUrl = null,
                    serialContentsTitle = null,

                    serialContent = emptyList(),
                    isSerialContentLoading = LoadState.RESET,

                    relatedContent = emptyList(),
                    isRelatedContentLoading = LoadState.RESET,

                    comments = null,
                    areMoreCommentsAvailable = false,
                    areCommentsLoading = LoadState.RESET
                )
                is Result.ContentLoaded -> {
                    copy(
                        content = result.contentLoad.content,
                        purchaseInfo = result.contentLoad.contentPurchaseInfo,
                        nextContent = result.contentLoad.nextContent,
                        canReport = result.contentLoad.canReport,
                        playable = result.contentLoad.playable,
                        playbackSupport = result.contentLoad.playbackSupport,
                        playableChild = result.contentLoad.playableChild,
                        canFav = result.contentLoad.canFav,
                        isFavorite = result.contentLoad.isFavorite,
                        canRate = result.contentLoad.canRate,
                        editUrl = result.contentLoad.editUrl,
                        shareUrl = result.contentLoad.shareUrl,
                        shareQrBase64 = result.contentLoad.shareQrBase64,
                        userRating = result.contentLoad.contentRating,
                        availableReportReasons = result.contentLoad.reportReasons,
                        productsUrl = result.contentLoad.productsUrl,
                        serialContentsTitle = result.contentLoad.serialContentsTitle,

                        isContentLoading = result.contentLoad.isContentLoading.asLoadState(),

                        serialContent = result.contentLoad.serialContent,
                        isSerialContentLoading = result.contentLoad.isSerialContentLoading.asLoadState(),

                        relatedContent = result.contentLoad.relatedContent,
                        isRelatedContentLoading = result.contentLoad.isRelatedContentLoading.asLoadState(),

                        comments = result.contentLoad.comments,
                        areMoreCommentsAvailable = result.contentLoad.comments?.areMoreCommentsAvailable() ?: false,
                        areCommentsLoading = result.contentLoad.areCommentsLoading.asLoadState(),
                    )
                }
                is Result.ErrorLoadingContent -> copy(
                    isContentLoading = LoadState.RESET
                )
                is Result.ErrorLoadingComments -> copy(
                    areCommentsLoading = LoadState.RESET
                )
                is Result.ErrorLoadingRelatedContent -> copy(
                    isRelatedContentLoading = LoadState.RESET
                )
                is Result.ErrorLoadingSerialContent -> copy(
                    isSerialContentLoading = LoadState.RESET
                )
                is Result.LoadedComments -> {
                    // Add the new loaded comments to the global list
                    val newComments = mutableListOf<Comment>()
                    comments?.comments?.let { newComments.addAll(it) }
                    newComments.addAll(result.comments.comments)
                    copy(
                        comments = Comments(
                            comments = newComments,
                            limit = result.comments.limit,
                            page = result.comments.page,
                            sort = result.comments.sort,
                            count = result.comments.count
                        ),
                        areMoreCommentsAvailable = result.comments.areMoreCommentsAvailable()
                    )
                }
                Result.AddedAsFavorite -> copy(
                    isFavorite = true
                )
                Result.RemovedFromFavorites -> copy(
                    isFavorite = false
                )
                is Result.ContentRated -> copy(
                    userRating = result.rating,
                    content = result.content
                )
                is Result.DeletedRating -> copy(
                    userRating = null,
                    content = result.content
                )
                is Result.CommentAdded -> {
                    // Add the new comment to the global list
                    val newComments = mutableListOf<Comment>()
                    newComments.add(result.comment)
                    comments?.comments?.let { newComments.addAll(it) }
                    copy(
                        comments = Comments(
                            comments = newComments,
                            limit = comments?.limit ?: DEFAULT_COMMENT_LIMIT,
                            page = comments?.page ?: 1,
                            sort = comments?.sort ?: DEFAULT_COMMENT_SORT,
                            count = comments?.count ?: 0,
                        ),
                        areMoreCommentsAvailable = false
                    )
                }
                is Result.CommentRemoved -> {
                    // Delete the comment on the global list
                    val updatedComments = mutableListOf<Comment>()
                    updatedComments.addAll(comments!!.comments)
                    val commentRemoved = updatedComments.find { it.id == result.commentId }
                    updatedComments.remove(commentRemoved)
                    copy(
                        comments = Comments(
                            comments = updatedComments,
                            limit = comments.limit,
                            page = comments.page,
                            sort = comments.sort,
                            count = comments.count
                        ),
                        areMoreCommentsAvailable = comments.areMoreCommentsAvailable()
                    )
                }
                is Result.CommentUpdated -> {
                    // Update the comment on the global list
                    val updatedComments = mutableListOf<Comment>()
                    updatedComments.addAll(comments!!.comments)
                    val commentUpdated = updatedComments.find { it.id == result.comment.id }
                    val index = updatedComments.indexOf(commentUpdated)
                    updatedComments[index] = result.comment
                    copy(
                        comments = Comments(
                            comments = updatedComments,
                            limit = comments.limit,
                            page = comments.page,
                            sort = comments.sort,
                            count = comments.count
                        ),
                        areMoreCommentsAvailable = comments.areMoreCommentsAvailable()
                    )
                }
                is Result.CommentsRefreshed -> copy(
                    comments = result.comments
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                is Intent.LoadContent -> loadContent(intent.id, intent.payload, intent.language)
                Intent.AddToFavorites -> addContentToFavorites(getState().content?.id)
                Intent.RemoveFromFavorites -> removeContentFromFavorites(getState().content?.id)
                is Intent.Rate -> rateContent(getState().content, intent.rating)
                Intent.DeleteRating -> deleteRating(getState().content?.id)
                is Intent.AddComment -> addComment(getState().content, intent.comment)
                is Intent.RemoveComment -> removeComment(getState().content?.id, intent.id)
                is Intent.UpdateComment -> updateComment(getState().content?.id, intent.id, intent.text)
                Intent.LoadMoreComments -> loadMoreComments(getState().content?.id, getState().comments?.page, getState().areMoreCommentsAvailable)
                Intent.RefreshComments -> refreshComments(getState().content?.id)
                is Intent.ReportContent -> reportContent(getState().content?.id, intent.reportId, intent.userMessage)
                Intent.LogDetailView -> logDetailView()
                is Intent.LogSelectItem -> logSelectItem(intent.contentId)
            }
        }

        override suspend fun executeAction(action: Action, getState: () -> State) { }

        private suspend fun loadContent(id: Int, payload: String?, language: String? = null) {
            dispatch(Result.ContentRequested)
            LoadContent(id, payload, language).invoke().collect { outcome ->
                when (outcome) {
                    is Success -> dispatch(Result.ContentLoaded(outcome.value))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is LoadContent.Error.UnavailableUser -> {
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                    is LoadContent.Error.UnavailableContent -> {
                                        dispatch(Result.ErrorLoadingContent)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                    is LoadContent.Error.UnavailableComments -> {
                                        dispatch(Result.ErrorLoadingComments)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                    is LoadContent.Error.UnavailableRelatedContent -> {
                                        dispatch(Result.ErrorLoadingRelatedContent)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                    is LoadContent.Error.UnavailableSerialContent -> {
                                        dispatch(Result.ErrorLoadingSerialContent)
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun loadMoreComments(contentId: Int?, lastPageLoaded: Int?, areMoreCommentsAvailable: Boolean) {
            if (contentId == null) {
                val error = "No content has been loaded. Can't load content comments without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else if (lastPageLoaded == null) {
                val error = "No first content comment page has been loaded. Can't load more comments without a first comment load"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                if (areMoreCommentsAvailable) {
                    when (val outcome = LoadCommentPage(contentId, lastPageLoaded + 1).invoke()) {
                        is Success -> dispatch(Result.LoadedComments(outcome.value))
                        is Failure -> {
                            errorService.handleError(
                                error = outcome.error,
                                sessionExpiredAction = { publish(Label.UserSessionExpired) },
                                requestTimeoutAction = { publish(Label.RequestTimedOut) },
                                handler = {
                                    when (outcome.error) {
                                        is LoadCommentPage.Error.UnavailableComments ->
                                            publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            )
                        }
                    }
                }
            }
        }

        private suspend fun addContentToFavorites(contentId: Int?) {
            if (contentId == null) {
                val error = "No content has been loaded. Can't add content to favorites without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = AddContentToFavorites(contentId).invokeWith(ComponentId.DETAIL)) {
                    is Success -> {
                        dispatch(Result.AddedAsFavorite)
                        val gameActionResult = SendGameAction(GameActions.FAV, contentId).invoke()
                        publish(Label.GameActionSent(gameActionResult))
                    }
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is AddContentToFavorites.Error.ContentAlreadyFavorited,
                                    is AddContentToFavorites.Error.UnavailableFavorites,
                                    is AddContentToFavorites.Error.UserSessionUnavailable ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun removeContentFromFavorites(contentId: Int?) {
            if (contentId == null) {
                val error = "No content has been loaded. Can't remove the content from favorites without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = RemoveContentFromFavorites(contentId).invokeWith(ComponentId.DETAIL)) {
                    is Success -> dispatch(Result.RemovedFromFavorites)
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is RemoveContentFromFavorites.Error.UnavailableFavorites,
                                    is RemoveContentFromFavorites.Error.UserSessionUnavailable -> {
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                    }
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun rateContent(content: Model.Content?, rating: Float) {
            if (content == null) {
                val error = "No content has been loaded. Can't rate the content without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = RateContent(content, rating).invoke()) {
                    is Success -> dispatch(Result.ContentRated(outcome.value.rating, outcome.value.content))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is RateContent.Error.RatingError ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun deleteRating(contentId: Int?) {
            if (contentId == null) {
                val error = "No content has been loaded. Can't delete the content rating without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = DeleteRating(contentId).invoke()) {
                    is Success -> dispatch(Result.DeletedRating(outcome.value))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is DeleteRating.Error.RatingError ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun addComment(content: Model.Content?, comment: String) {
            if (content == null) {
                val error = "No content has been loaded. Can't add a comment to a content without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = AddComment(content, comment).invoke()) {
                    is Success -> {
                        dispatch(Result.CommentAdded(outcome.value))
                        val gameActionResult = SendGameAction(GameActions.COMMENT, content).invoke()
                        publish(Label.GameActionSent(gameActionResult))
                    }
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is AddComment.Error.CommentError ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun removeComment(contentId: Int?, commentId: Int) {
            if (contentId == null) {
                val error = "No content has been loaded. Can't remove a comment to a content without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = RemoveComment(contentId, commentId).invoke()) {
                    is Success -> dispatch(Result.CommentRemoved(commentId))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is RemoveComment.Error.CommentError ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun updateComment(contentId: Int?, commentId: Int, comment: String) {
            if (contentId == null) {
                val error = "No content has been loaded. Can't update a comment from a content without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = UpdateComment(contentId, commentId, comment).invoke()) {
                    is Success -> dispatch(Result.CommentUpdated(outcome.value))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is UpdateComment.Error.CommentError ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun refreshComments(contentId: Int?) {
            if (contentId == null) {
                val error = "No content has been loaded. Can't refresh the comments of a content without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = RefreshComments(contentId).invoke()) {
                    is Success -> dispatch(Result.CommentsRefreshed(outcome.value))
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is RefreshComments.Error.UnavailableComments ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun reportContent(contentId: Int?, reportId: Int, userMessage: String? = null) {
            if (contentId == null) {
                val error = "No content has been loaded. Can't report a content without loading the content first"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                when (val outcome = ReportContent(contentId, reportId, userMessage).invoke()) {
                    is Success -> publish(Label.ContentReported)
                    is Failure -> {
                        errorService.handleError(
                            error = outcome.error,
                            sessionExpiredAction = { publish(Label.UserSessionExpired) },
                            requestTimeoutAction = { publish(Label.RequestTimedOut) },
                            handler = {
                                when (outcome.error) {
                                    is ReportContent.Error.ReportUnavailable ->
                                        publish(Label.UnexpectedError(outcome.error.getCode()))
                                }
                            }
                        )
                    }
                }
            }
        }

        private suspend fun logDetailView() {
            LogEvent(
                action = PageView,
                keyValue = PageView.PAGE_ID_KEY to PageView.DETAIL_VALUE
            ).invoke()
        }

        private suspend fun logSelectItem(contentId: Int) {
            LogEvent(
                action = SelectItem,
                contentId = contentId
            ).invoke()
        }
    }
}
