package es.cinfo.tiivii.core.layout

import es.cinfo.tiivii.core.layout.model.LayoutModel.Model.Screen
import es.cinfo.tiivii.core.layout.model.LegalSign
import es.cinfo.tiivii.core.layout.model.layoutconfig.Model.LayoutConfig
import es.cinfo.tiivii.core.profile.ProfileModel.Model.Profile
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.util.*
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.di.diContainer
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.currentCoroutineContext
import org.kodein.di.instance

/**
 * Service exposing app layout configuration values retrieved from the backend
 */
internal interface LayoutService {

    sealed class GetLegalError {
        object MissingLegalText: GetLegalError()
        data class UnavailableLegalText(val networkError: NetworkError): GetLegalError()
    }

    /**
     * Returns the global [LayoutConfig] for the app
     * @param profile [Profile] to retrieve the config for
     * @param language for translatable values. If not defined the default values in backend will be used for translatable values
     */
    suspend fun getLayoutConfig(profile: Profile, language: String): Outcome<LayoutConfig, NetworkError>

    /**
     * The last version of legal conditions signed by the user if any
     */
    suspend fun getSignedLegal(): LegalSign?

    /**
     * Signs the given legal conditions as a guest and stores it on disk with the data collection permissions
     */
    suspend fun signLegalAsGuest(legalVersion: Int, isDataCollectionAllowed: Boolean)

    /**
     * Obtains the screen layout with content information
     * @param id [Screen] to be retrieved
     * @param language to receive the translatable fields inside the [Screen]. If no language is defined
     * the default values given by the backend will be returned
     */
    suspend fun getScreen(id: Int, language: String): Outcome<Screen, NetworkError>

    /**
     * Returns the global [LayoutConfig.StyleConfig] for the app
     * @param profile [Profile] to retrieve the config for
     * @param language for translatable values. If not defined the default values in backend will be used for translatable values
     */
    suspend fun getStyleConfig(profile: Profile, language: String): Outcome<LayoutConfig.StyleConfig, NetworkError>

    suspend fun getLegal(version: Int): Outcome<LayoutConfig.Legal, GetLegalError>
}

/**
 * Default implementation of [LayoutService]
 */
internal class DefaultLayoutService() : LayoutService {
    private val layoutStorage: LayoutStorage by diContainer.instance()
    private val layoutApi: LayoutApi by diContainer.instance()

    private var layoutConfigLoading: LoadingModel.Model.LoadState = LoadingModel.Model.LoadState.RESET
    private lateinit var loadLayoutConfigs: Deferred<Outcome<LayoutConfig, NetworkError>>
    private lateinit var layoutConfigs: List<LayoutConfig>
    private var signedLegal: LegalSign? = null

    private val legals = TimedCache<String, LayoutConfig.Legal>()

    override suspend fun getLayoutConfig(profile: Profile, language: String): Outcome<LayoutConfig, NetworkError> {
        println("Richallll $layoutConfigLoading")
        return when (layoutConfigLoading) {
            LoadingModel.Model.LoadState.RESET -> {
                println("Richallll RESET")
                loadLayoutConfigs = CoroutineScope(currentCoroutineContext()).async {
                    layoutApi.getLayoutConfigs()
                        .map { configs ->
                            layoutConfigs = configs.mapNotNull { it.toModel(language)}
                            layoutConfigs.find { it.profile == profile }!!
                        }
                }
                layoutConfigLoading = LoadingModel.Model.LoadState.LOADING
                loadLayoutConfigs.await()
            }
            LoadingModel.Model.LoadState.LOADING -> {
                loadLayoutConfigs.await()
            }
            LoadingModel.Model.LoadState.LOADED -> {
                success(layoutConfigs.find { it.profile == profile }!!)
            }
        }
    }

    override suspend fun getSignedLegal(): LegalSign? {
        val value: LegalSign? = layoutStorage.getSignedLegal()
        if (value != null) {
            signedLegal = value
        }
        return signedLegal
    }

    override suspend fun signLegalAsGuest(legalVersion: Int, isDataCollectionAllowed: Boolean) {
        val legal = LegalSign(legalVersion, isDataCollectionAllowed)
        layoutStorage.storeLegal(legal)
        signedLegal = legal
    }

    override suspend fun getScreen(id: Int, language: String): Outcome<Screen, NetworkError> {
        return layoutApi.getScreen(id)
            .map {
                it.toModel() //it.toModel(language)
            }
    }

    override suspend fun getStyleConfig(profile: Profile, language: String): Outcome<LayoutConfig.StyleConfig, NetworkError> {
        return getLayoutConfig(profile, language)
            .map {
                it.styleConfig
            }
    }

    override suspend fun getLegal(version: Int): Outcome<LayoutConfig.Legal, LayoutService.GetLegalError> {
        return legals.get("legal-$version") {
            when (val legalOutcome = layoutApi.getLegals(version)) {
                is Failure -> legalOutcome.mapError { LayoutService.GetLegalError.UnavailableLegalText(it) }
                is Success -> {
                    val legal = legalOutcome.value.data.firstOrNull()
                    if (legal == null) {
                        failure(LayoutService.GetLegalError.MissingLegalText)
                    } else {
                        success(legal.toModel())
                    }
                }
            }
        }
    }

}