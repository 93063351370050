package es.cinfo.tiivii.home.store

import com.arkivanov.mvikotlin.core.store.Store
import es.cinfo.tiivii.core.content.model.WidgetModel
import es.cinfo.tiivii.core.modules.game.model.GameModel
import es.cinfo.tiivii.home.store.HomeStore.*
import kotlinx.coroutines.Job

internal interface HomeStore :
    Store<Intent, State, Label> {

    sealed class Action

    sealed class Intent {
        object LoadDefaultScreen: Intent()
        data class LoadDefaultScreenLang(
            val language: String?
        ): Intent()
        data class LoadWidgets(
            val screenId: Int,
            val language: String
        ) : Intent()
        data class AddToFavorites(
            val contentId: Int
        ): Intent()
        data class RemoveFromFavorites(
            val contentId: Int
        ): Intent()
        object LogHomeView : Intent()
        data class LogSelectItem(val contentId: Int) : Intent()
    }

    data class State(
        val loadedScreen: Int?,
        val screenWidgets: List<WidgetModel.Model.WidgetContentLoad>,
        val widgetLoadJob: Job?
    )

    sealed class Label {
        object UserSessionExpired : Label()
        object RequestTimedOut : Label()
        data class GameActionSent(
            val actionResult: GameModel.Model.ActionResult
        ) : Label()
        data class UnexpectedError(val code: String) : Label()
    }

}