package es.cinfo.tiivii.core.modules.video

import es.cinfo.tiivii.core.content.model.ContentTypeModel
import es.cinfo.tiivii.core.content.model.Model
import es.cinfo.tiivii.core.modules.video.model.VideoModel.Model.Video
import es.cinfo.tiivii.core.error.NetworkError
import es.cinfo.tiivii.core.modules.video.model.VideoModel
import es.cinfo.tiivii.core.util.Outcome
import es.cinfo.tiivii.core.util.map
import es.cinfo.tiivii.core.util.success
import es.cinfo.tiivii.di.diContainer
import kotlinx.serialization.json.JsonNull.content
import org.kodein.di.bindings.ErasedContext.type
import org.kodein.di.instance

internal interface VideoService {

    /**
     * Retrieves the associated [Video]'s of the given content
     * @param content [Model.Content] we want to retrieve the [Video]'s if
     * @param type [Video.Type] of the [Video]'s we want
     */
    suspend fun getVideoUrls(content: Model.Content, type: VideoModel.Model.Type? = null): Outcome<List<Video>, NetworkError>

    suspend fun getPlaybackPlaylist(): Set<Int>

    suspend fun addContentToPlaybackPlaylist(contentId: Int)

    suspend fun resetPlaybackPlaylist()
}

internal class DefaultVideoService : VideoService {
    private val videoApi: VideoApi by diContainer.instance()

    private val currentPlaybackPlaylist = mutableSetOf<Int>()

    override suspend fun getVideoUrls(content: Model.Content, type: VideoModel.Model.Type?): Outcome<List<Video>, NetworkError> {
        return when (content.type) {
            ContentTypeModel.Model.ContentType.VOD,
            ContentTypeModel.Model.ContentType.VOD_360,
            ContentTypeModel.Model.ContentType.VOD_FORKED -> {
                if (content.nimbleUrls.isNotEmpty()) {
                    if (type != null) {
                        success(content.nimbleUrls.filter { it.type == type })
                    } else {
                        success(content.nimbleUrls)
                    }
                } else if (content.adminTiiviiUrl != null) {
                    success(listOf(Video(
                        url = content.adminTiiviiUrl,
                        type = VideoModel.Model.Type.HLS
                    )))
                } else {
                    if (content.smil != null) {
                        videoApi.getVodUrls(content.smil).map {
                            it.toModel(type)
                        }
                    } else {
                        success(emptyList())
                    }
                }
            }
            ContentTypeModel.Model.ContentType.LIVE -> {
                videoApi.getStreamUrls(content.author).map {
                    it.toModel(type)
                }
            }
            ContentTypeModel.Model.ContentType.LIVE_TIIVII -> {
                if (content.streaming.isNotEmpty()) {
                    success(content.streaming)
                } else {
                    videoApi.getStreamUrls(content.author).map {
                        it.toModel(type)
                    }
                }
            }
            ContentTypeModel.Model.ContentType.NEWS,
            ContentTypeModel.Model.ContentType.CONTAINER,
            ContentTypeModel.Model.ContentType.AR,
            ContentTypeModel.Model.ContentType.VR,
            ContentTypeModel.Model.ContentType.IFRAME,
            ContentTypeModel.Model.ContentType.PDF,
            ContentTypeModel.Model.ContentType.EX_VOD,
            ContentTypeModel.Model.ContentType.EX_LIVE,
            ContentTypeModel.Model.ContentType.EX_YOUTUBE,
            ContentTypeModel.Model.ContentType.UNKNOWN -> {
                success(emptyList())
            }
        }
    }

    override suspend fun getPlaybackPlaylist(): Set<Int> {
        return currentPlaybackPlaylist
    }

    override suspend fun addContentToPlaybackPlaylist(contentId: Int) {
        currentPlaybackPlaylist.add(contentId)
    }

    override suspend fun resetPlaybackPlaylist() {
        currentPlaybackPlaylist.clear()
    }


}