package es.cinfo.tiivii.core.features.content.store

import com.arkivanov.mvikotlin.core.store.Reducer
import com.arkivanov.mvikotlin.core.store.SimpleBootstrapper
import com.arkivanov.mvikotlin.core.store.Store
import com.arkivanov.mvikotlin.core.store.StoreFactory
import com.arkivanov.mvikotlin.extensions.coroutines.SuspendExecutor
import es.cinfo.tiivii.core.ComponentId
import es.cinfo.tiivii.core.error.ErrorService
import es.cinfo.tiivii.core.features.content.model.Model.Content
import es.cinfo.tiivii.core.features.content.model.Model.ContentTreeLoad
import es.cinfo.tiivii.core.features.content.model.ViewModel
import es.cinfo.tiivii.core.features.content.store.ContentStore.*
import es.cinfo.tiivii.core.features.content.usecase.*
import es.cinfo.tiivii.core.modules.analytics.LogEvent
import es.cinfo.tiivii.core.modules.analytics.model.AnalyticsModel.Action.PageView
import es.cinfo.tiivii.core.sorting.SortModel.Model.Sort
import es.cinfo.tiivii.core.usecase.LoadDefaultOrders
import es.cinfo.tiivii.core.util.Failure
import es.cinfo.tiivii.core.util.LoadingModel.Model.LoadState
import es.cinfo.tiivii.core.util.Model
import es.cinfo.tiivii.core.util.Success
import es.cinfo.tiivii.di.diContainer
import org.kodein.di.instance

internal class ContentStoreFactory(
    private val storeFactory: StoreFactory,
) {

    private val errorService: ErrorService by diContainer.instance()

    private sealed class Result {
        data class LoadingContents(val id: Int, val loadedBy: LoadType, val isSearchAvailable: Boolean, val sort: Sort, val language: String) : Result()
        data class LoadingMoreContents(val nextPage: Int) : Result()
        data class FilteringContents(val queryFilter: String) : Result()
        data class SortingContents(val sort: Sort) : Result()
        data class ContentsUpdate(
            val contentLoad: ContentTreeLoad
        ) : Result()
        object ErrorLoadingContent: Result()
        data class DefaultOrders(val defaultOrders: List<Sort>) : Result()
    }

    fun create(): ContentStore =
        object :
            ContentStore,
            Store<Intent, State, Label> by
            storeFactory.create(
                name = "ContentStore",
                initialState = State(
                    title = null,
                    loadedBy = null,
                    id = null,
                    isSearchAvailable = false,
                    contents = null,
                    hasMoreContents = false,
                    queryFilter = null,
                    selectedSort = null,
                    availableSorts = null,
                    currentPage = 0,
                    loadingContents = LoadState.RESET
                ),
                bootstrapper = SimpleBootstrapper(Action.LoadOrders),
                reducer = DefaultReducer,
                executorFactory = ::DefaultExecutor
            ) {
        }

    private object DefaultReducer : Reducer<State, Result> {
        override fun State.reduce(result: Result): State =
            when (result) {
                is Result.LoadingContents -> copy(
                    loadedBy = result.loadedBy,
                    id = result.id,
                    isSearchAvailable = result.isSearchAvailable,
                    contents = null,
                    hasMoreContents = false,
                    queryFilter = null,
                    selectedSort = result.sort,
                    currentPage = 0,
                    loadingContents = LoadState.LOADING
                )
                is Result.LoadingMoreContents -> copy(
                    hasMoreContents = false,
                    currentPage = result.nextPage,
                    loadingContents = LoadState.LOADING
                )
                is Result.FilteringContents -> copy(
                    contents = null,
                    hasMoreContents = false,
                    queryFilter = result.queryFilter,
                    currentPage = 0,
                    loadingContents = LoadState.LOADING
                )
                is Result.SortingContents -> copy(
                    contents = null,
                    hasMoreContents = false,
                    selectedSort = result.sort,
                    currentPage = 0,
                    loadingContents = LoadState.LOADING
                )
                is Result.ContentsUpdate -> copy(
                    loadingContents = LoadState.LOADED,
                    title = result.contentLoad.title,
                    contents = result.contentLoad.contents,
                    hasMoreContents = result.contentLoad.hasMoreContent(),
                    selectedSort = result.contentLoad.sort,
                    currentPage = result.contentLoad.page
                )
                Result.ErrorLoadingContent -> copy(
                    loadingContents = LoadState.RESET
                )
                is Result.DefaultOrders -> copy(
                    selectedSort = result.defaultOrders.firstOrNull(),
                    availableSorts = result.defaultOrders
                )
            }
    }

    private inner class DefaultExecutor :
        SuspendExecutor<Intent, Action,
                State,
                Result, Label>() {
        override suspend fun executeIntent(intent: Intent, getState: () -> State) {
            when (intent) {
                is Intent.LoadContentByWidget -> loadContentByWidget(intent.id, getState().selectedSort!!, intent.language)
                is Intent.LoadContentByParticipant -> loadContentByParticipant(intent.id, getState().selectedSort!!, intent.language)
                is Intent.LoadContentByCategory -> loadContentByCategory(intent.id, getState().selectedSort!!, intent.language)
                is Intent.LoadContentById -> loadContentById(intent.id, getState().selectedSort!!, intent.language)
                Intent.LogView -> logView()
                Intent.LoadMoreContent ->
                    loadMoreContent(
                        getState().id,
                        getState().loadedBy,
                        getState().contents,
                        getState().queryFilter,
                        getState().currentPage,
                        getState().selectedSort,
                    )
                is Intent.Search -> filterContent(
                    intent.query,
                    getState().id,
                    getState().loadedBy,
                    getState().isSearchAvailable,
                    getState().selectedSort
                )
                Intent.ClearSearch -> clearSearch(
                    getState().id,
                    getState().loadedBy,
                    getState().isSearchAvailable,
                    getState().selectedSort
                )
                is Intent.Sort -> sortContent(intent.sortId, getState().id, getState().loadedBy, getState().queryFilter)

            }
        }

        override suspend fun executeAction(action: Action, getState: () -> State) {
            when (action) {
                Action.LoadOrders -> loadOrders()
            }
        }

        private suspend fun loadOrders() {
            when (val outcome = LoadDefaultOrders().invokeWith(ComponentId.CONTENT)) {
                is Success ->
                    dispatch(Result.DefaultOrders(outcome.value))
                is Failure -> {
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = {
                            publish(Label.UserSessionExpired)
                        },
                        requestTimeoutAction = {
                            publish(Label.RequestTimedOut)
                        },
                        handler = {
                            when (outcome.error) {
                                is LoadDefaultOrders.Error.EmptySortList ->
                                    publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun logView() {
            LogEvent(
                action = PageView,
                keyValue = PageView.PAGE_ID_KEY to PageView.CONTENT_VALUE
            ).invoke()
        }

        private suspend fun loadContentByWidget(id: Int, sort: Sort, language: String) {
            dispatch(Result.LoadingContents(id, LoadType.WIDGET, false, sort, language))
            when (val outcome = LoadContentsByWidget(id, sort, language).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is LoadContentsByWidget.Error.UnavailableContents,
                                LoadContentsByWidget.Error.UnsupportedWidgetType -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun loadContentByParticipant(id: Int, sort: Sort, language: String) {
            dispatch(Result.LoadingContents(id, LoadType.PARTICIPANT, true, sort, language))
            when (val outcome = LoadContentsByParticipant(id, sort).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is LoadContentsByParticipant.Error.UnavailableContents -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun loadContentByCategory(id: Int, sort: Sort, language: String) {
            dispatch(Result.LoadingContents(id, LoadType.CATEGORY, true, sort, language))
            when (val outcome = LoadContentsByCategory(id, sort).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is LoadContentsByCategory.Error.UnavailableContents -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun loadContentById(id: Int, sort: Sort, language: String) {
            dispatch(Result.LoadingContents(id, LoadType.SERIAL, true, sort, language))
            when (val outcome = LoadContentsById(id, sort).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is LoadContentsById.Error.UnavailableContents -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun loadMoreContent(
            id: Int?,
            loadedBy: LoadType?,
            contentTree: Model.Tree<ViewModel.Content, Content>?,
            queryFilter: String?,
            page: Int,
            sort: Sort?
        ) {
            if (id == null || loadedBy == null || contentTree == null || sort == null) {
                val error = "No contents have been previously loaded. Can't load more contents without a first load"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                val nextPage = page + 1
                dispatch(Result.LoadingMoreContents(nextPage))
                when (loadedBy) {
                    LoadType.WIDGET -> loadContentPageByWidget(id, contentTree, queryFilter, nextPage, sort)
                    LoadType.PARTICIPANT -> loadContentPageByParticipant(
                        id,
                        contentTree,
                        queryFilter,
                        nextPage,
                        sort
                    )
                    LoadType.CATEGORY -> loadContentPageByCategory(id, contentTree, queryFilter, nextPage, sort)
                    LoadType.SERIAL -> loadContentPageById(id, contentTree, nextPage, sort)
                }
            }
        }

        private suspend fun loadContentPageByWidget(
            id: Int,
            contentTree: Model.Tree<ViewModel.Content, Content>,
            queryFilter: String?,
            page: Int,
            sort: Sort
        ) {
            when (val outcome = LoadContentPageByWidget(
                id = id,
                contentTree = contentTree,
                queryFilter = queryFilter,
                page = page,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is LoadContentPageByWidget.Error.UnavailableContents,
                                LoadContentPageByWidget.Error.UnsupportedWidgetType -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun loadContentPageByParticipant(
            id: Int,
            contentTree: Model.Tree<ViewModel.Content, Content>,
            queryFilter: String?,
            page: Int,
            sort: Sort
        ) {
            when (val outcome = LoadContentPageByParticipant(
                id = id,
                contentTree = contentTree,
                queryFilter = queryFilter,
                page = page,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is LoadContentPageByParticipant.Error.UnavailableContents -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun loadContentPageByCategory(
            id: Int,
            contentTree: Model.Tree<ViewModel.Content, Content>,
            queryFilter: String?,
            page: Int,
            sort: Sort
        ) {
            when (val outcome = LoadContentPageByCategory(
                id = id,
                contentTree = contentTree,
                queryFilter = queryFilter,
                page = page,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is LoadContentPageByCategory.Error.UnavailableContents -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun loadContentPageById(
            id: Int,
            contentTree: Model.Tree<ViewModel.Content, Content>,
            page: Int,
            sort: Sort
        ) {
            when (val outcome = LoadContentPageById(
                id = id,
                contentTree = contentTree,
                page = page,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is LoadContentPageById.Error.UnavailableContents -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun clearSearch(id: Int?, loadedBy: LoadType?, isSearchAvailable: Boolean, sort: Sort?) {
            if (id == null || loadedBy == null || sort == null) {
                val error = "No contents have been previously loaded. Can't search without content loaded"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                dispatch(Result.LoadingContents(id, loadedBy, isSearchAvailable, sort, language = ""))
                when (loadedBy) {
                    LoadType.WIDGET -> loadContentByWidget(id, sort, language = "")
                    LoadType.PARTICIPANT -> loadContentByParticipant(id, sort, language = "")
                    LoadType.CATEGORY -> loadContentByCategory(id, sort, language = "")
                    LoadType.SERIAL -> loadContentById(id, sort, language = "")
                }
            }
        }

        private suspend fun sortContent(sortId: String, id: Int?, loadedBy: LoadType?, queryFilter: String?) {
            if (id == null || loadedBy == null) {
                val error = "No contents have been previously loaded. Can't sort without content loaded"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else {
                val sort = Sort.fromId(sortId)
                dispatch(Result.SortingContents(sort))
                when (loadedBy) {
                    LoadType.WIDGET -> sortContentForWidget(id, queryFilter, sort)
                    LoadType.PARTICIPANT -> sortContentForParticipant(id, queryFilter, sort)
                    LoadType.CATEGORY -> sortContentForCategory(id, queryFilter, sort)
                    LoadType.SERIAL -> sortContentForId(id, sort)
                }
            }
        }

        private suspend fun sortContentForWidget(
            id: Int,
            queryFilter: String?,
            sort: Sort
        ) {
            when (val outcome = SortContentForWidget(
                id = id,
                query = queryFilter,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is SortContentForWidget.Error.SortUnavailable,
                                SortContentForWidget.Error.UnsupportedWidgetType -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun sortContentForParticipant(
            id: Int,
            queryFilter: String?,
            sort: Sort
        ) {
            when (val outcome = SortContentForParticipant(
                id = id,
                query = queryFilter,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is SortContentForParticipant.Error.SortUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun sortContentForCategory(
            id: Int,
            queryFilter: String?,
            sort: Sort
        ) {
            when (val outcome = SortContentForCategory(
                id = id,
                query = queryFilter,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is SortContentForCategory.Error.SortUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun sortContentForId(
            id: Int,
            sort: Sort
        ) {
            when (val outcome = SortContentForId(
                id = id,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is SortContentForId.Error.SortUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }


        private suspend fun filterContent(
            queryFilter: String,
            id: Int?,
            loadedBy: LoadType?,
            searchAvailable: Boolean,
            sort: Sort?
        ) {
            if (id == null || loadedBy == null || sort == null) {
                val error = "No contents have been previously loaded. Can't filter without content loaded"
                errorService.handleStoreStateError(error) {
                    publish(Label.IllegalOperationError(error))
                }
            } else if (searchAvailable) {
                dispatch(Result.FilteringContents(queryFilter))
                when (loadedBy) {
                    LoadType.WIDGET -> filterContentForWidget(id, queryFilter, sort)
                    LoadType.PARTICIPANT -> filterContentForParticipant(id, queryFilter, sort)
                    LoadType.CATEGORY -> filterContentForCategory(id, queryFilter, sort)
                    LoadType.SERIAL -> filterContentForId(id, queryFilter, sort)
                }
            }
        }

        private suspend fun filterContentForWidget(
            id: Int,
            queryFilter: String?,
            sort: Sort
        ) {
            when (val outcome = FilterContentsForWidget(
                id = id,
                query = queryFilter,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is FilterContentsForWidget.Error.FilterUnavailable,
                                FilterContentsForWidget.Error.UnsupportedWidgetType -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun filterContentForParticipant(
            id: Int,
            queryFilter: String?,
            sort: Sort
        ) {
            when (val outcome = FilterContentsForParticipant(
                id = id,
                query = queryFilter,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is FilterContentsForParticipant.Error.FilterUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun filterContentForCategory(
            id: Int,
            queryFilter: String?,
            sort: Sort
        ) {
            when (val outcome = FilterContentsForCategory(
                id = id,
                query = queryFilter,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is FilterContentsForCategory.Error.FilterUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }

        private suspend fun filterContentForId(
            id: Int,
            queryFilter: String?,
            sort: Sort
        ) {
            when (val outcome = FilterContentsForId(
                id = id,
                query = queryFilter,
                sort = sort
            ).invoke()) {
                is Success -> dispatch(Result.ContentsUpdate(outcome.value))
                is Failure -> {
                    dispatch(Result.ErrorLoadingContent)
                    errorService.handleError(
                        error = outcome.error,
                        sessionExpiredAction = { publish(Label.UserSessionExpired) },
                        requestTimeoutAction = { publish(Label.RequestTimedOut) },
                        handler = {
                            when (outcome.error) {
                                is FilterContentsForId.Error.FilterUnavailable -> publish(Label.UnexpectedError(outcome.error.getCode()))
                            }
                        }
                    )
                }
            }
        }
    }
}