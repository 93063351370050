package es.cinfo.tiivii.core.layout.model

import es.cinfo.tiivii.core.content.model.Model
import es.cinfo.tiivii.core.content.model.WidgetModel
import es.cinfo.tiivii.core.modules.platform.PLATFORM_ID
import es.cinfo.tiivii.core.modules.platform.PlatformModel
import es.cinfo.tiivii.core.util.LoadingModel
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

sealed class LayoutModel {
    internal sealed class Model {
        data class ScreenLoad(
            val id: Int,
            val widgets: List<WidgetModel.Model.WidgetContentLoad>
        ) {
            fun hasCompleted(): Boolean {
                return widgets.all { it.loadState == LoadingModel.Model.LoadState.LOADED || it.loadState == LoadingModel.Model.LoadState.RESET }
            }
            fun unFreeze(): ScreenLoad {
                return if (PLATFORM_ID == PlatformModel.Model.Platform.IOS) {
                    val finalWidgets = mutableListOf<WidgetModel.Model.WidgetContentLoad>()
                    widgets.forEach { widgetLoad ->
                        val modifiableWidget = when (widgetLoad.widget) {
                            is WidgetModel.Model.Widget.ContentWidget.BannerLarge -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.Banner -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.BannerV2 -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.GridV2 -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.Circular -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.Featured -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.FeaturedV2 -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.DirectoV2 -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.Live -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.LiveVertical -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.News -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.Poster -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.PosterV2 -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.Progress -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.ProgressV2 -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.GroupWidget -> {
                                val modifiableWidgets = widgetLoad.widget.widgets.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(widgets = modifiableWidgets)
                            }
                            is WidgetModel.Model.Widget.ContentWidget.Live2 -> {
                                val modifiableContents = widgetLoad.widget.content.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(content = modifiableContents)
                            }
                            is WidgetModel.Model.Widget.ScreenWidget -> {
                                val modifiableScreens = widgetLoad.widget.widgets.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(widgets = modifiableScreens)
                            }
                            is WidgetModel.Model.Widget.BannerClick -> {
                                val modifiableBannerClick = widgetLoad.widget.widgets?.map {
                                    it.copy()
                                }
                                widgetLoad.widget.copy(widgets = modifiableBannerClick)
                            }
                            null -> null
                        }
                        finalWidgets.add(
                            widgetLoad.copy(widget = modifiableWidget)
                        )
                    }
                    ScreenLoad(
                        id = this.id,
                        widgets = finalWidgets
                    )
                } else {
                    this
                }
            }
        }
        data class Screen(
            val id: Int,
            val orderedWidgets: List<Widget>,
        ) {
            data class Widget(
                val id: Int,
                val type: WidgetModel.Model.WidgetType,
                val apiRequest: String?
            )
        }
    }
    internal sealed class ApiResponse {
        @Serializable
        data class Screen(
            @SerialName(ID_PARAM)
            val id: Int,
            @SerialName(WIDGETS_PARAM)
            val widgets: List<Widget>,
        ) {
            companion object {
                const val ID_PARAM = "id"
                const val WIDGETS_PARAM = "widgets"
            }

            @Serializable
            data class Widget(
                @SerialName(WIDGET_ID_PARAM)
                val widgetId: WidgetId,
            ) {
                companion object {
                    const val WIDGET_ID_PARAM = "widgets_id"
                }
                @Serializable
                data class WidgetId(
                    @SerialName(ID_PARAM)
                    val id: Int,
                    @SerialName(TYPE_PARAM)
                    val type: String,
                    @SerialName(API_REQUEST_PARAM)
                    val apiRequest: String?,
                ) {
                    companion object {
                        const val ID_PARAM = "id"
                        const val TYPE_PARAM = "type"
                        const val API_REQUEST_PARAM = "apirequest"
                    }
                }
            }

            fun toModel(): Model.Screen {
                val widgetIds = mutableListOf<Model.Screen.Widget>()
                widgets.forEach {
                    widgetIds.add(Model.Screen.Widget(
                        id = it.widgetId.id,
                        type = WidgetModel.Model.WidgetType.parse(it.widgetId.type),
                        apiRequest = it.widgetId.apiRequest
                    ))
                }
                return Model.Screen(
                    id = id,
                    orderedWidgets = widgetIds
                )
            }

        }
    }
}