package es.cinfo.tiivii.core.util

import es.cinfo.tiivii.core.date.DateService
import es.cinfo.tiivii.core.modules.analytics.AnalyticsApi
import es.cinfo.tiivii.core.modules.config.ConfigModule
import es.cinfo.tiivii.di.diContainer
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.features.*
import io.ktor.client.request.*
import io.ktor.client.statement.*
import io.ktor.http.*
import io.ktor.util.*
import io.ktor.util.pipeline.*
import kotlinx.serialization.json.JsonElement
import org.kodein.di.direct
import org.kodein.di.instance
import kotlin.native.concurrent.ThreadLocal

/**
 * Ktor feature implementation for automatic token renewal on expiration
 *
 * If a requests fails due to a token expiration this feature will trigger the [refreshToken] function to
 * renew the token and retry the petition only if at least [refreshTokenDelayMs] time has passed to avoid
 * excessive renewals
 *
 * @param refreshToken Function invocation that will be called when a token refresh is needed. It must return
 * the new token that should be used on the retry
 * @param refreshTokenDelayMs Indicates the minimum time between token refreshes that can be done. This avoids
 * excessive petitions for token renewal. Defaults to 5 minutes
 */
internal class TokenRenewal(
    val refreshToken: suspend () -> String?,
    val refreshTokenDelayMs: Long
) {

    class Configuration {
        lateinit var refreshToken: suspend () -> String?
        // This must be always be equal or less than the accessToken expiration time
        var refreshTokenDelayMs: Long = 2 * 60 * 1_000
    }

    @ThreadLocal
    companion object : HttpClientFeature<Configuration, TokenRenewal> {
        override val key = AttributeKey<TokenRenewal>("TokenRenewalFeature")

        private val dateService: DateService = diContainer.direct.instance()
        private val httpService: HttpService = diContainer.direct.instance()
        internal val configModule: ConfigModule = diContainer.direct.instance()

        override fun prepare(block: Configuration.() -> Unit): TokenRenewal {
            val config = Configuration().apply(block)

            with(config) {
                return TokenRenewal(refreshToken, refreshTokenDelayMs)
            }
        }

        override fun install(feature: TokenRenewal, scope: HttpClient) {
            scope.receivePipeline.intercept(HttpReceivePipeline.After) { response ->
                val parsedResponse = addResponse(response)
                if (isBackendEndpoint(response) &&
                    isTokenExpired(parsedResponse) &&
                    isRetryAvailable(feature.refreshTokenDelayMs)) {
                    feature.refreshToken()
                    httpService.setLastTokenRefresh(dateService.currentEpochMillis())
                    val requestBuilder = HttpRequestBuilder().takeFrom(context.request)
                    requestBuilder.headers.remove("Authorization")
                    proceedWith(scope.request(requestBuilder))
                } else {
                    proceed()
                }
            }
        }

        private suspend fun addResponse(response: HttpResponse): Pair<HttpResponse, JsonElement?> {
            var jsonResponse: JsonElement? = null
            val contentType = response.headers["content-type"]
            if (contentType?.contains("application/json") == true) {
                jsonResponse = response.receive<JsonElement>()
            }
            httpService.add(response, jsonResponse)
            return response to jsonResponse
        }

        private fun isBackendEndpoint(response: HttpResponse): Boolean =
            response.request.url.toString().startsWith(configModule.getEnvConfig().backendUrl)

        private fun isTokenExpired(parsedResponse: Pair<HttpResponse, JsonElement?>): Boolean {
            return parsedResponse.first.status == HttpStatusCode.Unauthorized ||
                    (parsedResponse.second != null && isUnauthorized(parsedResponse.second!!))
        }

        private fun isUnauthorized(parsedResponse: JsonElement): Boolean {
            val errorCode = getErrorCode(parsedResponse)
            return errorCode == 401
        }

        private fun isRetryAvailable(refreshTokenDelayMs: Long): Boolean {
            return httpService.getLastTokenRefreshMs() + refreshTokenDelayMs <= dateService.currentEpochMillis()
        }
    }
}

///**
// * Ktor feature implementation for automatic api request logs to the analytics service
// *
// * For every api request against backend a log will be sent to the analytics service to reflect the operation
// * (except for these api log calls)
// *
// * @param logApiCall The function to be executed when an api call is required to be logged
// */
//internal class ApiCallLog(
//    val logApiCall: suspend (String, Int?) -> Unit,
//) {
//
//    class Configuration {
//        lateinit var logApiCall: suspend (String, Int?) -> Unit
//    }
//
//    companion object : HttpClientFeature<Configuration, ApiCallLog> {
//        override val key = AttributeKey<ApiCallLog>("ApiCallLogFeature")
//
//        override fun prepare(block: Configuration.() -> Unit): ApiCallLog {
//            val config = Configuration().apply(block)
//
//            with(config) {
//                return ApiCallLog(logApiCall)
//            }
//        }
//
//        override fun install(feature: ApiCallLog, scope: HttpClient) {
//            scope.receivePipeline.intercept(HttpReceivePipeline.Before) { response ->
//                if (!isLogApiCall(response.request.url)) {
//                    feature.logApiCall(response.request.url.toString(), response.status.value)
//                }
//            }
//        }
//
//        private fun isLogApiCall(request: Url): Boolean {
//            return request.toString().contains(AnalyticsApi.ENDPOINT)
//        }
//
//    }
//}
