package es.cinfo.tiivii.core

import kotlin.String

internal object AppPackage {
  internal const val CORE_CONFIG: String =
      "{\"signup\":{\"mode\":{\"id\":\"simple\",\"defaultBirthday\":\"1800-01-01\"},\"availableProfiles\":[\"adult\"],\"defaultProfile\":\"adult\",\"defaultLanguage\":\"gl\",\"availableLanguages\":[\"es\",\"gl\"],\"enableOnBoarding\":false,\"startAsGuest\":true},\"content\":{\"interestFilterEnabled\":false,\"containerFilterEnabled\":false,\"productBuyoutEnabled\":true,\"orders\":[\"most_recent_first\",\"most_popular\",\"highest_score\",\"title_alphabetical\",\"title_alphabetical_reversed\"],\"enableComments\":false,\"enableRatings\":false,\"enableReports\":false,\"showContentTypeIcons\":false,\"detailImageType\":{\"web\":\"background\",\"android\":\"poster\",\"androidTv\":\"background\",\"ios\":\"poster\"},\"defaultImageQuality\":90,\"showAuthor\":false,\"showPublishDate\":false,\"contentFilter\":\"none\",\"showSeasonButton\":false,\"refreshUserValuesOnContentLoad\":\"never\",\"shuffleRelatedContent\":false,\"showSplashVideo\":true},\"support\":{\"supportEmail\":null,\"faqUrl\":null,\"tutorialUrl\":null,\"showAppUrls\":false},\"legal\":{\"enabled\":true},\"user\":{\"showAvatars\":{\"web\":true,\"android\":true,\"androidTv\":true,\"ios\":false},\"profileButtonPosition\":\"bottom-bar\"},\"gamification\":{\"enabled\":false},\"billing\":{\"enabled\":false}}"

  internal const val ENV_CONFIG: String =
      "{\"tenant\":\"lupus\",\"core\":{\"projectGroup\":\"es.cinfo.tiivii\",\"envs\":[{\"name\":\"pro\",\"realm\":\"lupus\",\"apiName\":\"lupus\",\"backendUrl\":\"https://streamer-cdn.ott.tiivii.com/v1\",\"frontendUrl\":\"https://hubrural.gal\",\"secret\":\"cinfo-lupus-api-secret\",\"loggingEnabled\":false,\"firebaseDynamicLinkUrl\":\"https://lupus.page.link\",\"firebaseDynamicLinkHost\":\"com.lupus\",\"firebaseApiKey\":\"AIzaSyBl13EsvgG0yBAya6KgF2RfbQQlvnJR0z8\",\"baseKeycloakUri\":\"https://hubrural.gal/auth/\",\"confKeycloakUri\":\"https://hubrural.gal/auth/realms/lupus/.well-known/openid-configuration\",\"tokenKeycloakUri\":\"https://hubrural.gal/auth/realms/lupus/protocol/openid-connect/token\",\"userInfoKeycloakUri\":\"https://hubrural.gal/auth/realms/lupus/protocol/openid-connect/userinfo\",\"logoutKeycloakUri\":\"https://hubrural.gal/auth/realms/lupus/protocol/openid-connect/logout\",\"webClientId\":\"default-web\",\"appClientId\":\"default-app\"}],\"android\":{\"compileSdkVersion\":33,\"minSdkVersion\":21,\"targetSdkVersion\":33},\"web\":{\"jsModuleKind\":\"umd\",\"output\":{\"targetDir\":\"/web/app/public/modules/core\",\"jsFileName\":\"core.js\",\"libraryName\":\"core\",\"moduleName\":\"core\"}},\"ios\":{\"frameworkName\":\"core\",\"podSummary\":\"Framework of the core shared codebase\",\"podHomepage\":\"https://git.cinfo.es/ott/petiscos-apps\",\"deploymentTarget\":\"12.1\"}},\"web\":{\"appName\":\"hubrural.gal\"},\"android\":{\"mobile\":{\"appName\":\"Hubrural\",\"appId\":\"es.cinfo.lupus\",\"flavorName\":\"mobile\",\"compileSdkVersion\":33,\"minSdkVersion\":21,\"targetSdkVersion\":33,\"appUrl\":\"\"},\"tv\":{\"appName\":\"Hubrural\",\"appId\":\"es.cinfo.lupus.tv\",\"flavorName\":\"tv\",\"compileSdkVersion\":33,\"minSdkVersion\":22,\"targetSdkVersion\":33,\"appUrl\":\"\"}},\"ios\":{\"appName\":\"Hubrural\",\"appId\":\"\",\"bundleId\":\"\",\"appUrl\":\"https://apps.apple.com/es/app/PetisGO/id1571115749\",\"teamId\":\"\"}}"

  internal const val TENANT: String = "lupus"

  internal const val ENV: String = "pro"
}
